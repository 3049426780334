$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.button {
    composes: global(button-reset);
    composes: global(text-button);
    composes: global(px-3);
    composes: global(cx-2);
}

.label {
    composes: global(hide-until-large);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-transform: uppercase;

    @at-root .inactive & {
        color: var(--color-text-subtle);
    }
}

.inactive {
}

.icon {
    flex: none;
    width: 16px;
    height: 16px;

    svg {
        path[data-name="red"] {
            fill: #d80027;
        }
        path[data-name="white"] {
            fill: #f0f0f0;
        }
        path[data-name="blue"] {
            fill: #0052b4;
        }
    }

    @at-root .inactive & {
        path[data-name="blue"],
        path[data-name="red"] {
            fill: var(--color-icon-primary-disabled);
        }
    }
}
