$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

@import "_calendar-variables.scss";

.container {
    composes: global(pb-6);

    display: grid;
    grid-template-columns: $ylabels-container-width 1fr;
    grid-template-rows: $xlabels-container-height 1fr;

    position: relative;
    overflow: auto;
    background-color: $labels-bg-color;
    border-radius: var(--border-radius);

    width: 100%;

    scroll-snap-type: x mandatory;
    scroll-padding-left: $ylabels-container-width;

    height: calc(100vh - #{$top-bar-height + 79px});

    @include mq($until: xl) {
        overscroll-behavior: none;
    }
}

.containerExtendedHeader {
    height: calc(100vh - #{$top-bar-height + 176px});
    // TODO want this, but it's causing prod build error until we upgrade sass
    // height: calc(100dvh - #{$top-bar-height + 111px});

    @include mq(md) {
        height: calc(100vh - #{$top-bar-height + 79px});
    }
}

.pinnedContainer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.labelsCover {
    position: sticky;
    top: 0;
    left: 0;
    width: $ylabels-container-width;
    height: calc(#{$xlabels-container-height} + #{$ylabel-offset} + 2px);
    border-top-left-radius: var(--border-radius);
    z-index: $z-xlabel-cover;

    // fade clipped y axis labels
    background: linear-gradient(
        0deg,
        rgba($labels-cover-bg-color, 0) 0%,
        rgba($labels-cover-bg-color, 1) 25%,
        rgba($labels-cover-bg-color, 1) 100%
    );

    // fade clipped x axis labels
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 100%;
        transform: translateX(100%);
        background: linear-gradient(270deg, rgba($labels-cover-bg-color, 0) 0%, rgba($labels-cover-bg-color, 1) 100%);
    }
}

.axisText {
    composes: global(text-subheading);
    color: var(--color-text-subtle);
}

.xAxisLabelsContainer {
    composes: axisText;

    position: sticky;
    top: 0;

    flex: 0 0 $xlabels-container-height;
    display: flex;
    align-items: center;
    background-color: $labels-bg-color;
    user-select: none;
    z-index: $z-xlabels-container;
}

.xAxisLabel {
    composes: global(px-3);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    scroll-snap-align: start;
    overflow: hidden;
}

.xAxisLabelText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.xAxisLabelHighlighted {
    composes: xAxisLabel;
    color: var(--color-text-white);
    border-radius: var(--border-radius);
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 6px);
        height: 100%;
        background-color: var(--color-interactive-primary-default);
        opacity: 1;
        border-radius: var(--border-radius);
        z-index: -1;
    }
}

.yAxisLabelsContainer {
    composes: axisText;

    position: sticky;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: $labels-bg-color;
    flex: 0 0 $ylabels-container-width;
    width: $ylabels-container-width;
    user-select: none;
    z-index: $z-ylabels-container;

    // extend bg under offset labels
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: $ylabel-double-offset;
        width: 100%;
        height: -1 * $ylabel-double-offset + 1px;
        background-color: $labels-bg-color;
    }
}

.yAxisLabel {
    composes: global(pr-2);

    flex: 0 0 $grid-double-inc-y;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    transform: translateY($ylabel-offset);
}

.grid {
    left: $ylabels-container-width;
    top: $xlabels-container-height;
    display: grid;
    grid-template-rows: repeat(96, $grid-inc-y);
    border-bottom: 1px solid #dde1e6;
    background-size: $grid-min-column-width $grid-inc-y;
    background-image: linear-gradient(to right, #dde1e6 1px, transparent 1px),
        linear-gradient(to bottom, #dde1e6 1px, #fafafa 1px);
}

.gridNavigationContainer {
    position: relative;
}

.gridNav {
    position: absolute;
    top: 40%;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    z-index: $z-grid-navigation;
}

.gridNavDisabled {
    visibility: hidden;
    opacity: 0;
}

.gridNavPrevious {
    composes: gridNav;
    left: calc(#{$ylabels-container-width} + var(--spacing-5));

    svg {
        transform: rotate(90deg);
    }
}

.gridNavNext {
    composes: gridNav;
    right: var(--spacing-5);

    svg {
        transform: rotate(-90deg);
    }
}

.gridNavPreviousDisabled {
    composes: gridNavPrevious;
    composes: gridNavDisabled;
}

.gridNavNextDisabled {
    composes: gridNavNext;
    composes: gridNavDisabled;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--color-surface-background--rgb), 0.5);
    z-index: $z-loading-overlay;
}
