// Convert SASS map of css property names / values into CSS output.
// Properties named `description` will have their value inserted as comments.
// Nested maps will be processed recursively.
// @param {map} $map the map of properties to output
@mixin map-to-props($map) {
    @if type-of($map) == map {
        @each $prop, $value in $map {
            @if type-of($value) != map {
                @if inspect($prop) == "description" {
                    /* #{inspect($value)} */
                } @else {
                    #{inspect($prop)}: #{inspect($value)};
                }
            } @else {
                @include map-to-props($value);
            }
        }
    }
}

// output css for a single settings variant. Assumes structure:
// $settings > 'breakpoints' > $bp > 'variants'[ $style ] > map of css properties / values
// @param {settings} $settings map of settings
// @param {style} $style key of style to target
@mixin responsive-setting-to-props($settings, $style) {
    $breakpoints: map-get($settings, "breakpoints");
    @each $bp, $values in $breakpoints {
        @include mq($bp) {
            $variants: map-get($values, "variants");
            $props: map-get($variants, $style);
            @include map-to-props($props);
        }
    }
}

// ouput css for multiple settings variants. Assumes structure:
// $settings > 'breakpoints' > $bp > variants > $variant > map of css properties / values
// @param {settings} $settings map of settings
// @param {prefix} $prefix selector prefix to preceed variant keys
@mixin responsive-settings-to-props($settings, $prefix: "") {
    $breakpoints: map-get($settings, "breakpoints");
    @each $bp, $values in $breakpoints {
        $variants: map-get($values, variants);
        @include mq($bp) {
            @each $variant, $props in $variants {
                $selector: "#{$prefix}#{$variant}";
                // also create %placeholder selector
                %#{$selector},
                .#{$selector} {
                    @include map-to-props($props);
                }
            }
        }
    }
}

// output css for breakpoint based values over a map of properties. Assumes structure:
// $settings > 'breakpoints' > key : value
// @param {$properties} map of properties to map with values
// selector is constructed as follows: .{property key}-{value key}
@mixin responsive-values-over-props($settings, $properties, $prefix: "", $includeInverse: false) {
    $breakpoints: map-get($settings, "breakpoints");
    @each $bp, $values in $breakpoints {
        @include mq($bp) {
            @each $value-key, $value in $values {
                @each $prop-key, $props in $properties {
                    $selector: "#{$prefix}#{$prop-key}-#{$value-key}";
                    // also create %placeholder selector
                    %#{$selector},
                    .#{$selector} {
                        @each $prop in $props {
                            #{inspect($prop)}: #{inspect($value)};
                        }
                    }
                    @if ($includeInverse) {
                        $selector: "#{$prefix}#{$prop-key}--#{$value-key}";
                        // also create %placeholder selector
                        %#{$selector},
                        .#{$selector} {
                            @each $prop in $props {
                                #{inspect($prop)}: #{inspect(-$value)};
                            }
                        }
                    }
                }
            }
        }
    }
}

// output css vars for breakpoint based values Assumes structure:
// $settings > 'breakpoints' > $bp > $value-key : $value
// @param {$settings} map of breakpoint based values
// @param {$prefix} var prefix (should not include --)
// var is constructed as follows: --{prefix}{key} : {value}
@mixin responsive-settings-to-vars($settings, $prefix: "", $includeInverse: false) {
    $breakpoints: map-get($settings, "breakpoints");
    @each $bp, $values in $breakpoints {
        @include mq($bp) {
            @each $value-key, $value in $values {
                --#{$prefix}#{inspect($value-key)}: #{inspect($value)};
                @if ($includeInverse) {
                    --#{$prefix}#{inspect("-"+$value-key)}: #{inspect(-$value)};
                }
            }
        }
    }
}

// output css vars for simple key/value map
// @param {$settings} map of key/value pairs
// @param {$prefix} var prefix (should not include --)
// var is constructed as follows: --{prefix}{key} : {value}
@mixin settings-to-vars($settings, $prefix: "") {
    @each $key, $value in $settings {
        --#{$prefix}#{inspect($key)}: #{inspect($value)};
    }
}

// output css for breakpoint based container spacing. Assumes structure:
// $settings > 'breakpoints' > value key (0-9) : value (n px)
// @param {$properties} map of key/css properties to map with values
// selector is constructed as follows: .{property key}-{value key}
@mixin responsive-containers($settings, $properties, $prefix: "", $includeInverse: false) {
    $breakpoints: map-get($settings, "breakpoints");
    @each $bp, $values in $breakpoints {
        @include mq($bp) {
            @each $value-key, $value in $values {
                @each $prop-key, $props in $properties {
                    $selector: "#{$prefix}#{$prop-key}-#{$value-key}";
                    // also create %placeholder selector
                    %#{$selector},
                    .#{$selector} {
                        > * + * {
                            @each $prop in $props {
                                #{inspect($prop)}: #{inspect($value)};
                            }
                        }
                    }
                    // add inverse
                    @if ($includeInverse) {
                        $selector: "#{$prefix}#{$prop-key}--#{$value-key}";
                        // also create %placeholder selector
                        %#{$selector},
                        .#{$selector} {
                            > * + * {
                                @each $prop in $props {
                                    #{inspect($prop)}: #{inspect(-$value)};
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
