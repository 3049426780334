$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.switchContainer {
    &:hover:not(.disabled) {
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        margin: 0;
        padding: 0;

        &:checked + div {
            background-color: var(--color-action-primary-default);

            .switchIcon {
                transform: translate3d(-20px, -50%, 0);
            }

            &::after {
                left: calc(100% - 2px);
                transform: translateX(-100%);
            }
        }

        &:enabled + div:active::after {
            width: 24px;
        }

        &:enabled:checked + div:active {
            background-color: var(--color-action-primary-pressed);
        }

        &:enabled:not(checked) + div:active {
            background-color: var(--color-icon-primary-pressed);
        }

        &:disabled:checked + div {
            background-color: var(--color-interactive-primary-disabled);
        }

        &:focus + div {
            @include focus-outline();
        }
    }
}

.pip {
    width: 44px;
    height: 22px;
    background-color: var(--color-interactive-primary-disabled);
    display: block;
    border-radius: $border-radius-xl;
    position: relative;
    transition: background-color 0.2s;

    &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        background-color: var(--color-icon-white-default);
        height: 18px;
        width: 18px;
        border-radius: $border-radius-xl;
        transition: all 0.3s;
        filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
    }
}

.switchIcon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    * {
        fill: var(--color-icon-white-default);
    }
}
