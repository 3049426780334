@import "src/common/scaffolding/components/variables";

// Global Antd css overrides.
// Note: We are transitioning away from this approach. Prefer custom styles where possible.

.ant-btn,
.ant-checkbox-wrapper,
.ant-input,
.ant-input-group-addon,
.ant-list,
.ant-form,
.ant-list-item,
.ant-transfer-list-header,
.ant-transfer-list-content-item-text,
.ant-table,
.ant-select,
.ant-tag:not(.ant-tag-cyan):not(.ant-tag-checkable-checked),
.ant-tag a,
.ant-tag a:hover {
    color: rgba(0, 0, 0, 0.65);
}

.ant-tag,
.ant-btn:not(.ant-btn-circle),
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-input {
    border-radius: 4px;
}

.ant-input {
    &[type="color"] {
        height: 40px;
    }
}

.ant-btn-dangerous {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;

    &:hover,
    &:hover {
        color: #fff;
        background-color: #ff7875;
        border-color: #ff7875;
    }

    &:active,
    &.active {
        color: #fff;
        background-color: #d9363e;
        border-color: #d9363e;
    }
}

.ant-transfer-list-content-item-checked {
    background-color: unset;
}

.ant-select-multiple .ant-select-selection-item {
    border-color: #e8e8e8;
}

.ant-btn {
    &:not(.ant-btn-primary):not(.ant-btn-dangerous) {
        &:hover,
        &:focus {
            color: var(--theme-primary-hover-color);
            border-color: currentColor;
        }

        &:active {
            color: var(--theme-hard-color);
            border-color: currentColor;
        }
    }

    // dont override default ant disabled states
    &-primary.ant-btn:not([disabled]) {
        color: #fff;
        background-color: var(--theme-primary-color);
        border-color: var(--theme-primary-color);

        &:hover,
        &:focus {
            color: #fff;
            background-color: var(--theme-primary-hover-color);
            border-color: var(--theme-primary-hover-color);
        }

        &:active {
            background-color: var(--theme-hard-color);
            border-color: var(--theme-hard-color);
        }
    }
}

.ant-btn-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    > .anticon {
        display: inline-flex;
    }
}

// for buttons who want to appear disabled without actually being disabled
.ant-btn-primary.ant-btn:not([disabled]).--disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}

button.ant-btn[disabled]:hover,
.ant-btn[disabled]:not(.ant-btn-primary):not(.ant-btn-dangerous):hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: $color-grey-3;
    border-color: $color-grey-5;
}

.ant-select.ant-select:not(.ant-select-disabled) {
    &:hover {
        .ant-select-selector {
            border-color: var(--theme-primary-hover-color);
        }
    }
    &:focus,
    &:active {
        .ant-select-selector {
            border-color: var(--theme-primary-color);
        }
    }
}

.ant-select-focused.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
        border-color: var(--theme-primary-hover-color);
    }
}

.ant-select {
    .ant-select-selection {
        &:hover {
            border-color: var(--theme-primary-hover-color);
        }

        &:active,
        &:focus {
            border-color: var(--theme-primary-color);
        }
    }

    &.ant-select-focused {
        &:hover {
            border-color: var(--theme-primary-hover-color);
        }

        &:active,
        &:focus {
            border-color: var(--theme-primary-color);
        }
    }

    &.ant-select-open {
        .ant-select-selection {
            border-color: var(--theme-primary-color);
        }
    }
}

.ant-spin-dot i {
    background-color: var(--theme-primary-color);
}

.ant-alert {
    .ant-alert-message {
        color: $color-grey-9;
    }

    .ant-alert-description {
        color: $color-grey-8;
    }
}

thead.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
tbody.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
thead.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
tbody.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: $color-grey-2;
}

.ant-tag-checkable:active,
.ant-tag-checkable-checked {
    background-color: var(--theme-primary-color);
    color: #fff;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: var(--theme-primary-color);
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
    &.ant-tag-cyan {
        color: var(--theme-primary-hover-color);
        background: var(--theme-soft-color);
        border-color: currentColor;
    }
}

.ant-checkbox {
    &-wrapper span {
        user-select: none;
    }

    &-checked .ant-checkbox-inner {
        background-color: var(--theme-primary-color);
        border-color: var(--theme-primary-color);
    }

    &-indeterminate .ant-checkbox-inner:after {
        background-color: var(--theme-primary-color);
    }

    &:hover .ant-checkbox-inner {
        border-color: var(--theme-primary-color);
    }
}

.ant-input {
    &:focus {
        border-color: var(--theme-primary-color);
    }

    &:hover {
        border-color: var(--theme-primary-hover-color);
    }
}

.ant-input-group-addon {
    user-select: none;
}

input::placeholder {
    user-select: none;
}

.ant-form-item-control.has-success {
    .ant-input-group-addon {
        border-color: #52c41a;
        color: #52c41a;
    }

    .ant-input {
        border-color: #52c41a;
        color: #52c41a;
    }

    .ant-form-explain {
        color: #52c41a;
    }
}
