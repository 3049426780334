@mixin buttonReset {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
    background: transparent;
    color: inherit;
    font-family: inherit;
    cursor: pointer;
    text-align: center;
    appearance: none;
    padding: 0;
}
