$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

button.icon {
    display: flex;
}

button.icon::before {
    content: "";
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

button.icon.selected::before {
    filter: invert(100%);
}

.icon.dietary-dairyfree::before {
    content: url(../../../assets/icons/dietary-dairyfree.svg);
}

.icon.dietary-mild-spicy::before {
    content: url(../../../assets/icons/dietary-mild-spicy.svg);
}
.icon.dietary-medium-spicy::before {
    content: url(../../../assets/icons/dietary-medium-spicy.svg);
}
.icon.dietary-spicy::before {
    content: url(../../../assets/icons/dietary-spicy.svg);
}

.icon.dietary-glutenfree::before {
    content: url(../../../assets/icons/dietary-glutenfree.svg);
}

.icon.dietary-vegan::before {
    content: url(../../../assets/icons/dietary-vegan.svg);
}

.icon.dietary-vegetarian::before {
    content: url(../../../assets/icons/dietary-vegetarian.svg);
}

.icon.dietary-gluten-free::before {
    content: url(../../../assets/icons/dietary-glutenfree.svg);
}

.icon.dietary-halal::before {
    content: url(../../../assets/icons/dietary-halal.svg);
}

.icon.dietary-kosher::before {
    content: url(../../../assets/icons/dietary-kosher.svg);
}

.icon.dietary-nogarlic::before {
    content: url(../../../assets/icons/dietary-nogarlic.svg);
}

.icon.dietary-noonion::before {
    content: url(../../../assets/icons/dietary-noonion.svg);
}

.icon.dietary-quick::before {
    content: url(../../../assets/icons/dietary-quick.svg);
}

.icon.dietary-seasonal::before {
    content: url(../../../assets/icons/dietary-seasonal.svg);
}

.icon.modifier-icon::before {
    content: url(../../../assets/icons/modifier-icon.svg);
}

.icon.modifier-icon-background::before {
    background-image: url(../../../assets/icons/modifier-icon.svg);
    content: "";
}
