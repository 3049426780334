$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.edit-brand {
    padding: 24px;

    &__theme-text-color {
        position: absolute;
        top: 0;
        padding: 11px 20px;
        text-transform: uppercase;
        color: white;
        font-size: 11px;
        letter-spacing: 0.8px;
        pointer-events: none;
    }

    .imageupload--container {
        &.small-upload-image {
            .ant-upload {
                max-height: 265px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .ant-upload-drag-container {
                    height: 265px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .imageupload--preview {
                height: 100%;
                img {
                    width: initial;
                    height: 100%;
                }
            }
        }
        &.hero-upload-image {
            .ant-upload,
            .imageupload--preview img {
                max-height: 265px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .ant-upload-drag-container {
                    height: 265px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
