// app header
$top-bar-height: 64px;

// action footer at bottom of forms
$action-footer-height: 76px;

// width of expanded menu on large screens
$menu-width: 267px;

// editable content width limits
$content-narrow: 756px;

$content-standard: 1000px;

$content-wide: 1200px;

$form-min-width: 280px;
