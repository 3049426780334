$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(px-5);
    composes: global(py-3);
    composes: global(cx-3);
    background-color: var(--color-black);
    border-radius: var(--border-radius);
    display: flex;
    width: auto;
    align-items: center;
    color: var(--color-text-white);
    pointer-events: none;
    user-select: none;
}

.icon--loading,
.icon--info {
    color: var(--color-icon-highlight-default);
}

.icon--success {
    color: var(--color-icon-success-default);
}

.icon--warning {
    color: var(--color-icon-warning-default);
}

.icon--error {
    color: var(--color-icon-critical-default);
}

.body {
    composes: global(text-body);
}
