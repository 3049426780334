.actionsContainer {
    composes: global(cx-3);
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.errorContainer {
    composes: global(px-4);
    padding-top: calc(var(--spacing-3) - 1px);
    padding-bottom: calc(var(--spacing-3) - 1px);
    border-radius: var(--border-radius);
    width: 100%;
    background-color: var(--color-surface-critical);
    border: 1px solid var(--color-border-critical-default);
    color: var(--color-text-critical);
}
