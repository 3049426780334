$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.content--container div.content__col.content__col--catalogue {
    //custom scrolling implemented so make sure we don't see 2
    overflow-y: hidden;
}

.poscatalogue-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .catalogue-search {
        @include mq($until: lg) {
            grid-column: 1 / -1;
        }

        @include mq(lg) {
            max-width: 230px;
        }
    }

    .add-new-item {
        margin-bottom: 0;
    }

    .header-row {
        display: flex;
        max-width: 1000px;
        flex-direction: column;

        @include mq($until: md) {
            > * + * {
                margin-top: 16px;
            }
        }

        @include mq(md) {
            flex-direction: row;
            align-items: flex-end;
            > * + * {
                margin-left: 16px;
            }
        }

        .export-csv-btn {
            @include mq(lg) {
                margin-left: auto;
            }
        }
    }
    .ant-col.col-content {
        div.ant-table-wrapper {
            padding-bottom: 0;
            tr.ant-table-row.ant-table-row-level-1 td:last-child {
                background-color: #fafafa;
            }

            tr.ant-table-row.ant-table-row-level-1 {
                &:hover td {
                    background-color: #fafafa;
                }
            }
        }
    }
    .catalogue-tab-content {
        display: flex;
        flex-direction: column;
        position: relative;
        .ant-tabs-bar {
            margin: 0;
        }
        .ant-tabs-content-top {
            max-height: calc(100vh - 60px - 72px - 100px);
            .ant-tabs-tabpane {
                padding-top: 16px;
                padding-right: 24px;
                overflow-y: auto;
            }
        }

        &.warning-visible {
            .ant-tabs-content-top {
                max-height: calc(100vh - 60px - 72px - 100px - 39px);
            }
        }

        .ant-tabs-content {
            overflow-y: auto;
        }

        &.no-paging {
            .ant-tabs-content-top {
                max-height: calc(100vh - 60px - 124px);
            }

            .catalogue-warning {
                bottom: 0;
            }
        }

        &.warning-visible.no-paging {
            .ant-tabs-content-top {
                max-height: calc(100vh - 60px - 124px - 39px);
            }
        }
    }

    .validation-status {
        display: block;

        &--INVALID,
        &--MISSING {
            color: #ff4d4f;
        }

        &--PENDING {
            color: rgba(0, 0, 0, 0.25);
        }

        &__icon {
            margin-left: 5px;

            &--VALID {
                color: #237804;
            }
        }
    }

    tr > td.col {
        &-catalogue-status {
            width: 130px;
        }

        &-catalogue-price {
            width: 80px;
        }

        &-catalogue-category {
            width: 210px;
        }

        &-catalogue-sku {
            width: 150px;
        }
    }

    .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td.col-catalogue-image {
        padding: 8px;
        width: 110px;
    }

    .publish-status-0 {
        color: #bfbfbf;
    }

    .publish-status-1 {
        color: #fb9a33;
    }

    .publish-status-2 {
        color: #237804;
    }

    .missing-content {
        background: $color-gold-1;
        color: $color-gold-2;
        border: 1px solid $color-grey-4;
        box-sizing: border-box;

        a i {
            color: $color-gold-2;
        }
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq(lg) {
            max-width: 200px;
        }
    }

    .filter-wrapper {
        span {
            color: $color-grey-9;
        }

        @include mq(lg) {
            max-width: 200px;
        }
    }

    .header-buttons {
        margin-left: auto;
    }

    .catalogue-warning {
        position: fixed;
        bottom: 50px;
        margin-left: -24px;
        padding-left: 24px;
        z-index: 5;
        width: 100%;
        cursor: pointer;
        color: $color-grey-8;

        i {
            color: $color-gold-3;
            margin-right: 9px;
        }
        b {
            color: $color-gold-2;
        }
    }
}

.ant-btn.hidden {
    display: none;
}

.catalogue-upload-modal.ant-modal {
    top: 5%;

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        position: relative;
        width: 100%;

        border-radius: $border-radius-large;

        .ant-modal-close {
            display: none;
        }

        .ant-modal-body {
            padding: 0;
        }
    }
}

//large custom break point to handle aligning with table max-size
@media screen and (max-width: 1260px) {
    .ant-col.col-content {
        div.ant-table-wrapper {
            ul.ant-pagination.ant-table-pagination {
                padding-right: 24px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ant-col.col-content {
        div.ant-table-wrapper {
            ul.ant-pagination.ant-table-pagination {
                padding-right: 24px;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .ant-col.col-content {
        div.ant-table-wrapper {
            ul.ant-pagination.ant-table-pagination {
                display: block;
                width: 100%;
                left: 0;
                text-align: center;
                padding: 12px 0;

                > li:first-child {
                    width: 100%;
                }
            }
        }
    }

    .catalogue-wrapper {
        .catalogue-warning {
            bottom: 80px;
        }
    }
}
