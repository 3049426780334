html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.text-reset {
    @include textReset;
}

.list-reset {
    @include listReset;
}

.button-reset {
    @include buttonReset;
}

.link-reset {
    @include linkReset;
}

.input-reset {
    @include inputReset;
}
