$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.sortable-transfer {
    text-align: left;
    padding-top: 20px;

    .ant-transfer-list-header-selected span {
        user-select: none;
    }

    .ant-transfer-list-content-item {
        user-select: none;

        &.--is-dragging {
            .ant-checkbox-wrapper {
                visibility: hidden;
            }
        }

        .ant-transfer-list-content-item-text {
            .icon {
                margin-right: 2px;
            }

            .icon::before {
                background-size: 10px 10px;
                display: inline-block;
                width: 10px;
                height: 10px;
            }
        }
    }

    &:not(.dragging) {
        .ant-transfer-list:last-child .ant-transfer-list-content-item > span > *[data-react-beautiful-dnd-draggable] {
            display: inline !important; // beautiful-dnd's inline styles make long items appear as "..." when not dragging
        }
    }

    &.dragging {
        .ant-transfer-list:last-child .ant-transfer-list-content-item {
            overflow: visible;
        }
    }

    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover,
    .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
        background-color: var(--theme-soft-color);
    }

    .ant-dropdown-trigger {
        display: none;
    }

    .ant-transfer-list-header-selected {
        padding-left: 6px;
    }
}
