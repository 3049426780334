$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    position: relative;
}

.tooltip {
    composes: global(text-caption);
    @include elevation();
    display: inline-block;
    background-color: var(--color-surface-neutral-strong);
    color: var(--color-text-white);
    padding: 6px 8px;
    border-radius: var(--border-radius);
    max-width: 200px;
    z-index: $z-tooltip;
}

.arrow,
.arrow::before {
    position: absolute;
    width: 8.5px;
    height: 8.5px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
}

.tooltip[data-popper-placement^="top"] > .arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
    top: -4px;
}

.tooltip[data-popper-placement^="left"] > .arrow {
    right: -4px;
}

.tooltip[data-popper-placement^="right"] > .arrow {
    left: -4px;
}

.tooltip[data-popper-reference-hidden="true"],
.tooltip[data-popper-reference-hidden="true"] > .arrow::before {
    visibility: hidden;
    pointer-events: none;
}
