$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: $z-popup;
    overflow: hidden;
    // background-color: rgba(0, 0, 0, 0.15); // debug
}

.containerCollapsed {
    composes: container;
    width: 0;
    height: 0;
    pointer-events: none;
}

.containerExpanded {
    composes: container;
    pointer-events: auto;
}

.popup {
    @include card();
    @include elevation(large);
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    outline-color: var(--color-border-highlight-default);
}

.popupExpanded {
    composes: popup;
    composes: zoomUpIn from "styles/shared/styles.scss";
    visibility: visible;
}

.popupCollapsed {
    composes: popup;
    composes: zoomUpOut from "styles/shared/styles.scss";
    visibility: hidden;
}
