$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(cy-6);
    min-width: $form-min-width;
}

.list {
    composes: global(list-reset);

    // make cards full width on small screens
    @include mq($until: md) {
        width: calc(100% + var(--spacing-7) * 2);
        @include mx(-7);
    }
}

.headerRow {
    @include px(6);
    @include py(4);
    width: 100%;
    @include grid(
        (
            "gridGutters": (
                xs: (
                    x: 12px,
                    y: 12px,
                ),
            ),
        )
    );
    min-height: 64px;
    @include mq($until: md) {
        display: none;
    }
}

.heading {
    composes: global(text-subheading);
}

.columnLeftAlign {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.columnRightAlign {
    text-align: right;
    display: flex;
    flex-direction: column;
}
