$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

// React Day Picker overrides.
// Should only contain classes expected by ReactDayPicker component
// @see "classNames" at https://react-day-picker.js.org/api/DayPicker/

/* @private */
$cellSize: 40px;

/* @private */
@mixin columnContainer() {
    display: grid;
    grid-template-columns: repeat(7, $cellSize + 2px);
    grid-auto-rows: $cellSize + 2px;
    align-items: center;
    justify-items: center;
}

.container {
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-surface-default);
    display: inline-flex;
}

.interactionDisabled {
    cursor: auto;
}

.wrapper {
    @include elevation();
    position: relative;
    flex-direction: row;
    user-select: none;
}

.months {
    composes: global(py-3);
    composes: global(px-4);
    composes: global(cx-4);
    display: flex;
    flex-wrap: wrap;
}

.month {
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
}

.navBar {
    display: flex;
    justify-content: space-between;
    height: 46px;
    width: 100%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

.weekdays {
    display: block;
}

.weekdaysRow {
    @include columnContainer;
}

.weekday {
    composes: global(text-button);
    color: var(--color-text-subtle);
    text-align: center; 

    abbr[title] {
        border-bottom: none;
        text-decoration: none;
        cursor: auto;
    }
}

.body {
}

.week {
    @include columnContainer;
}

.day {
    composes: global(text-body);
    font-weight: normal;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    color: var(--color-text-default);
    position: relative;
}

.today {
    color: var(--color-interactive-primary-default);
}

.disabled {
    color: var(--color-text-disabled);
    opacity: 0.5;
    cursor: default;
}

.outside {
    color: var(--color-text-disabled);
    cursor: default;
}

.rangeSelected {
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: var(--color-action-secondary-hover);
        width: 100%;
        height: 75%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    font-weight: 500;
    opacity: 1;
    color: var(--color-text-default);
}

.selected {
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: $cellSize;
        height: $cellSize;
        transform: translate(-50%, -50%);
        background-color: var(--color-interactive-primary-default);
        border-radius: 20px;
        z-index: 3;
    }

    color: white;
    
    &.today {
        color: white;
    }

    &.disabled {
        opacity: 1;
    }
}

.start {
    composes: rangeSelected;
    composes: selected;

    &:before {
        width: 50%;
        transform: translate(0, -50%);
    }
}

.end {
    composes: rangeSelected;
    composes: selected;

    &:before {
        left: 0%;
        width: 50%;
        transform: translate(0, -50%);
    }
}

.footer {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.12);
}

.todayButton {
    composes: global(button-reset);
    composes: global(text-button);
    composes: global(py-3);
    composes: global(px-5);
    color: var(--color-interactive-primary-default);

    @media (hover: hover) {
        &:hover {
            color: var(--color-interactive-primary-hover);
        }
    }

    &:active {
        color: var(--color-interactive-primary-pressed);
    }
}
