$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.container {
    width: 100%;
    outline: none;

    @include safe-focus-removal {
        &:focus {
            border: 2px solid var(--color-border-highlight-default);
        }
    }
}

.containerDragging {
    composes: container;
    @include elevation(large);
}

.fieldsContainer {
    width: calc(100% + var(--spacing-4));
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--spacing-4));
    margin-top: calc(-1 * var(--spacing-4));
}

.fieldsContainer--1 {
    composes: fieldsContainer;
}

.fieldsContainer--2 {
    composes: fieldsContainer;
}

.fieldContainer {
    composes: global(mt-4);
    composes: global(ml-4);

    @include mq(xl) {
        min-width: 0;
    }

    button {
        height: 100%;
    }

    input {
        width: 100%;
    }
}

.fieldContainerBig {
    composes: fieldContainer;
    flex: 2;
    min-width: calc(100% - var(--spacing-4));

    @include mq(xl) {
        max-width: none;
        min-width: 0;
    }
}

.fieldContainerStandard {
    composes: fieldContainer;
    flex: 1;
    min-width: calc(100% - var(--spacing-4));

    @at-root .fieldsContainer--1 & {
        @include mq(480px) {
            min-width: 0;
        }
    }

    @at-root .fieldsContainer--2 &:not(.fieldContainerStandardLast) {
        @include mq(320px) {
            min-width: calc(50% - var(--spacing-4));
            max-width: calc(50% - var(--spacing-4));
        }

        @include mq(640px) {
            min-width: 0;
        }
    }
}

.fieldContainerStandardLast {
    composes: fieldContainerStandard;
    max-width: none;
    min-width: 0px;
}

.fieldContainerFixed {
    composes: fieldContainer;
    flex: none;
}

.subtle {
    color: var(--color-text-subtle);
}
