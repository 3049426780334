$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.group-tabs-stats {
    margin-right: 24px;
    max-width: 1000px;
    min-width: 320px;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    width: 100%;
    margin: 16px 0 46px;

    &__box {
        border: 1px solid #e6e6e6;
        border-radius: 16px;
        width: 100%;
        min-width: fit-content;
        padding: 16px 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:not(:first-child) {
            margin-left: 20px;
        }

        @at-root .group-tabs-stats--disabled & {
            background-color: $color-grey-2;
        }

        @at-root .group-tabs-stats--empty & {
            background-color: white;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $color-grey-7;
        margin-bottom: 8px;

        @at-root .group-tabs-stats--disabled & {
            color: $color-grey-6;
        }
    }
    &__value {
        font-weight: 500;
        font-size: 36px;
        line-height: 36px;
        color: $color-grey-9;

        @at-root .group-tabs-stats--disabled & {
            color: $color-grey-7;
        }

        @at-root .group-tabs-stats--empty & {
            color: $color-grey-4;
        }
    }
}

@media screen and (max-width: 900px) {
    .group-tabs-stats {
        flex-direction: column;
        width: initial;
        align-items: center;
        margin: 16px 0 32px;

        &__box {
            padding: 8px 6px;

            &:not(:first-child) {
                margin-top: 16px;
                margin-left: 0;
            }
        }

        &__title {
            font-size: 14px;
            margin-bottom: 2px;
        }
        &__value {
            font-size: 24px;
        }
    }
}
