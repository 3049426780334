$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.menu {
    composes: global(list-reset);
    composes: global(cy-2);
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    scrollbar-width: none; // hide on FF

    // TODO move to mixin?
    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
}

.itemText {
    font-size: 14px;
}

.listItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.container {
    composes: itemText;
}

.item {
    composes: global(pr-3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    height: 30px;
}

.parentMenuItem {
    composes: item;
}

.parentMenuItem0 {
    composes: parentMenuItem;
}

.parentMenuItem1 {
    composes: parentMenuItem;
    padding-left: 18px;
}

.item0 {
    composes: item;
    composes: global(pl-4);
}

.item1 {
    composes: item;
    padding-left: 32px;
}

.item2 {
    composes: item;
    padding-left: 66px;
}

.submenuButton {
    composes: global(button-reset);
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: none;
    justify-content: center;
    margin-right: 2px;
    padding: 2px;
    position: relative;
    width: 30px;
    outline: none;

    &:disabled {
        cursor: auto;
    }

    @media (hover: hover) {
        &:hover:not(:disabled) {
            &::before {
                content: "";
                position: absolute;
                left: 2px;
                top: 2px;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                background: rgba(0, 0, 0, 0.08);
                border-radius: 6px;
                color: black;
            }
        }
    }

    @include safe-focus-removal {
        &:focus:not(:active):not(:disabled) {
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid var(--color-border-highlight-default);
                border-radius: $border-radius;
            }
        }
    }
}

.submenuArrow {
    transition: all 0.1s ease;
    flex: none;
}

.submenuArrowCollapsed {
    composes: submenuArrow;
    transform: rotate(90deg);
}

.submenuArrowExpanded {
    composes: submenuArrow;
    transform: rotate(180deg);
}

.submenuContent {
    composes: global(list-reset);
    overflow: hidden;
    transition: 0.15s ease;
}
