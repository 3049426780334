$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

@import "_calendar-variables.scss";

.container {
    composes: global(px-3);
    composes: global(py-2);
    composes: global(text-caption);
    color: var(--color-text-default);
    position: relative;
    margin: 6px;
    border-radius: var(--border-radius);
}

.link {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    background-color: var(--color-surface-darken);
    transition: all 0.2s;

    &:hover {
        color: var(--color-text-default);
        opacity: 0.09;
    }
}

.detailsContainer {
    position: sticky;
    top: $xlabels-container-height;
    overflow: hidden;
    min-height: 32px;
    line-height: 16px;
    z-index: $z-service-details;
    pointer-events: none;
}

.prominent {
    font-weight: 600;
}

.additional {
    font-style: italic;
    overflow-wrap: break-word;
}

.attributes {
    display: inline-flex;
    composes: global(mt-2);
    composes: global(cx-2);
    pointer-events: auto;
    user-select: none;
}

.attributeIcon {
    display: inline-flex;
    width: 16px;
    height: 16px;
    color: var(--color-text-white);
    background-color: var(--color-icon-primary-default);
    border-radius: 9999px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    user-select: none;
}

.attributeIconAlt {
    composes: attributeIcon;
    color: var(--color-icon-primary-default);
    border: 1px solid var(--color-icon-primary-default);
    background-color: var(--color-surface-default);
}

// prettier-ignore
@each $key, $value in (
    0: #957bfd,
    1: #d4bbff,
    2: #edbbff,
    3: #ffafd2,
    4: #ffac70,
    5: #ffdb70,
    6: #3ddbd9,
    7: #82cfff,
    8: #a6c8ff,
    9: #ad98ff,
    10: #e8daff,
    11: #f4d3ff,
    12: #ffd6e8,
    13: #ffc499,
    14: #ffebad,
    15: #9ef0f0,
    16: #bae6ff,
    17: #d0e2ff
){
    .color#{inspect($key)} {
        background-color: $value;
    }
}
