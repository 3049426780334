$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
}

.default {
    composes: container;
    composes: global(text-subheading);
    composes: global(px-3);
    composes: global(cx-2);
    border-radius: 9999px;
    height: 20px;

    svg {
        width: 12px;
    }
}

.nowrap {
    white-space: nowrap;
}

.text--light {
    color: var(--color-text-white);
}

.text--default {
    color: var(--color-text-default);
}

.bg--action {
    background-color: var(--color-action-primary-default);
}

.bg--light {
    background-color: var(--color-surface-neutral);
}

.bg--mid {
    background-color: var(--color-icon-primary-default);
}

.bg--dark {
    background-color: var(--color-black);
}

.bg--disabled {
    background-color: var(--color-icon-primary-disabled);
}

.bg--none {
    background-color: transparent;
}

.circle {
    width: 20px;
    padding: 0;
    justify-content: center;

    svg {
        margin-right: 0;
    }
}

.dot {
    width: 8px;
    height: 8px;
    padding: 0;
}
