$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.topRow {
    composes: global(mb-4);
    display: flex;
    align-items: center;
}

.row {
    display: flex;
    align-items: center;
}

.actionsContainer {
    composes: global(cx-3);
    margin-left: auto;
    display: flex;
    align-items: center;
    z-index: 35; // Needs to be greater than Calendar ($z-header _calendar-variables) on Services page for the included Service view menu on mobile 
}

.backButton {
    display: block;
}

.title {
    composes: global(text-reset);
    composes: global(text-page-heading);
    color: var(--color-text-default);
}

.titleAfter {
    composes: global(ml-4);
}

.subtitle {
    composes: global(text-reset);
    composes: global(mt-3);
    composes: global(text-body);
    color: var(--color-text-subtle);
}
