$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

// TODO temporary until CMS redesign migration
.content__col--pricelistsedit {
    .ant-col.col-content div.ant-table-wrapper .ant-pagination.ant-table-pagination {
        height: 76px;
        margin-top: 0;
        align-items: center;
    }

    .header-row {
        padding: 5px 0 20px;
        display: grid;
        row-gap: 16px;
        column-gap: 16px;
        grid-template-columns: 1fr;

        .pricelevel-search {
            grid-column: 1 / -1;

            @include mq(md) {
                max-width: 450px;
            }
        }

        @include mq(375px) {
            grid-template-columns: 1fr 1fr;
        }

        @include mq(md) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-end;
            max-width: 1000px;

            > *:nth-child(2) {
                margin-left: auto;
            }
        }
    }
}

// FIXME leaky styles
.ant-layout.ant-layout-has-sider > .ant-layout {
    overflow: hidden !important;
}

.titleRow {
    display: flex;
    padding-bottom: 0;
    align-items: center;

    .link-back {
        margin-right: 10px;
    }

    h2 {
        margin-bottom: 0;
    }
}

div.ant-table-wrapper.price-levels-table {
    .num-field {
        padding: 0 8px !important;

        input {
            min-width: 70px;
            text-align: right;
        }
    }
    .col-type {
        width: 160px;
    }

    .col-sku {
        width: 140px;
    }

    .col-price {
        width: 80px;
    }

    .edit-form--commands {
        padding: 20px 0;
    }

    th {
        white-space: nowrap;
    }

    tbody.ant-table-tbody {
        .child-icon {
            margin-left: 20px;
            margin-right: 5px;
        }
        .error-icon {
            color: $color-red-2;
            margin-right: 5px;
        }

        .ant-input.invalid {
            border: 1px solid $color-red-2;
        }

        tr.ant-table-row-selected,
        tr.ant-table-row-selected td,
        tr.ant-table-row-selected.ant-table-row-level-1 td {
            background-color: $color-red-3;
            color: $color-red-2;

            input {
                color: $color-red-2;
            }
        }
    }
    .ant-table-selection-col,
    .ant-table-selection-column {
        display: none;
    }

    .col-actions {
        display: none;
    }

    th.col-actions button.btn-delete-all {
        border: none;

        &.selected {
            color: $color-red-2;
        }
    }

    &.editing {
        .col-actions {
            width: 40px;
            display: table-cell;
            button {
                color: rgba(0, 0, 0, 0.65);

                &:hover,
                &:active {
                    border: none;
                }
            }
        }
    }
}

.content__col--pricelistsedit {
    .edit-form--commands {
        margin-left: -24px;
        padding: 10px 24px;
        button.ant-btn {
            margin-left: 0;
        }
    }
}

// TODO temp stying to make price lists drawer and action footer work
.price-levels-drawer {
    .ant-drawer-body {
        flex: none;
        height: calc(100% - 131px);
    }

    .ant-drawer-content {
        position: relative;
    }
}

.price-levels-alert {
    max-width: 1000px;
}

.price-list__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    position: static;
    flex: none;
}
