$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: var(--color-surface-background);
    z-index: $z-sticky-header - 1;
}

.stickyContainer {
    @include mq(md) {
        @include px(7);

        // use hidden scroll bar to align with content
        &::-webkit-scrollbar {
            background-color: transparent;
        }

        @at-root :global(.content-has-scroll) & {
            overflow-y: scroll;
        }
    }

    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: $z-sticky-header;
}

.innerContainer {
    width: 100%;
}

:global(.content--narrow) .innerContainer {
    max-width: $content-narrow;
}

:global(.content--standard) .innerContainer {
    max-width: $content-standard;
}

:global(.content--wide) .innerContainer {
    max-width: $content-wide;
}
