/* 
 * Mixin provided as a last resort when it is not possible to use a typography class
 * Use sparingly!
 */
@mixin text($style) {
    @include responsive-setting-to-props($typography, $style);
}

@mixin textReset {
    margin: 0;
    padding: 0;
}
