$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

@import "_calendar-variables.scss";

.container {
    position: sticky;
    top: -1px;
    left: 0;
    transition: background-color 0.1s;
    z-index: $z-header;

    @include p(6);
    height: calc(40px + var(--spacing-6) * 2);
}

.containerExtended {
    composes: container;

    @include p(4);
    height: calc(80px + var(--spacing-4) * 2 + var(--spacing-3));

    @include mq(md) {
        @include p(6);
        height: calc(40px + var(--spacing-6) * 2);
    }
}

.pinnedContainer {
    background-color: $labels-bg-color;
}
