$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.bulk-uploader {
    button {
        margin: 8px 8px 0 0;
    }

    .modal-close {
        position: absolute;
        top: 14px;
        right: 14px;
        color: $color-grey-1;
        z-index: 1;
        font-size: 24px;
        svg {
            padding: 2px;
        }
        &-black {
            color: $color-black;
        }
    }

    .result-pane {
        max-height: 200px;
        overflow-y: auto;
        margin-top: 12px;

        .import-success {
            text-align: center;
            i {
                color: $color-green;
            }
        }
    }

    h1 {
        color: $color-grey-1;
        margin-bottom: 0;
        font-size: 30px;
    }
    h2 {
        color: $color-grey-8;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 600;
    }
    h3 {
        color: $color-grey-8;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
    }
    h4 {
        color: $color-grey-8;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 400;
    }

    &--content {
        position: absolute;
        left: 0;
        background: $color-grey-1;
        border-radius: $border-radius-large;

        .loader__container--overlay {
            border-radius: $border-radius-large;
            background: #26262670;
        }
    }
    &--header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 84px 24px 32px;
        position: relative;
        min-height: 248px;
        max-height: 260px;
        background: $color-grey-9;
        border-radius: $border-radius-large $border-radius-large 0px 0px;
        color: $color-grey-1;
        text-align: center;

        i {
            font-size: 64px;
        }

        .icon.orion-import-database {
            filter: invert(100%) sepia(0%) saturate(6976%) hue-rotate(150deg) brightness(107%) contrast(112%);
            content: url(../../../../assets/icons/orion-import-database.svg);
            height: 64px;
        }

        i.info-circle {
            font-size: 48px;
            content: url(../../../../assets/icons/orion-info-help.svg);
            min-height: 64px;
        }
    }
    &--header.failed {
        color: $color-red-2;
        background: $color-red-3;
        h1 {
            color: $color-red;
        }
        i {
            filter: invert(54%) sepia(93%) saturate(7204%) hue-rotate(343deg) brightness(94%) contrast(106%);
            content: url(../../../../assets/icons/orion-priority-warning.svg);
            min-height: 64px;
        }
    }

    &--header.success {
        background: $color-green;
        i {
            content: url(../../../../assets/icons/orion-checked-circle.svg);
            min-height: 64px;
        }
    }

    &--body {
        align-items: center;
        padding: 24px 24px 16px 24px;

        .body-top {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            a {
                margin-top: 16px;
            }
        }

        .upload-wrapper {
            margin-top: 24px;
            height: 180px;
            width: 100%;
        }

        .body-top.failed {
            p {
                padding: 16px;
            }
            i {
                color: $color-red-2;
            }
        }

        div.import-error {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $color-red-3;
            color: $color-red;
            padding: 16px;
            p {
                padding-top: 8px;
                margin: 0;
            }
            i {
                font-size: 24px;
                padding-bottom: 8px;
            }

            h3 {
                color: $color-red-2;
                font-weight: 600;
            }
            a {
                margin-top: 0;
                color: $color-red;
            }
        }

        .import-success {
            padding: 8px;
            p {
                padding-top: 8px;
                margin: 0;
            }
            i {
                color: $color-green;
            }

            i.invalid {
                color: $color-grey-6;
            }
        }
        .review {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px;
            height: 110px;
            background: $color-grey-3;
            color: $color-grey-7;
            h3 {
                padding-top: 8px;
                color: $color-grey-7;
            }

            i {
                font-size: 24px;
                color: $color-grey-6;
            }
        }

        .info {
            font-size: 12px;
            margin-top: 24px;
            span {
                display: block;
            }
        }
    }

    &--footer {
        margin: 0 24px 24px 24px;
        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 40px;
            padding: 8px 16px;
        }
    }
    div.ant-upload-drag {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fafafa;
        border: 1px dashed #d9d9d9;
        box-sizing: border-box;
        border-radius: $border-radius-small;
        max-height: 145px;
        i.cloud-upload {
            content: url(../../../../assets/icons/orion-upload-to-cloud.svg);
        }
        p.ant-upload-hint {
            font-size: 12px;
        }
        h4 {
            color: $color-grey-9;
        }
    }

    .ant-upload-list-item-info {
        text-align: left;
        color: $color-blue-3;
    }
}

.fade-enter {
    opacity: 0;
}
.fade-exit {
    opacity: 0.5;
}
.fade-enter-active {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
}
