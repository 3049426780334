@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

@mixin palette($settings, $interactive-keys, $static-keys, $prefix: "color-", $value-prefix: "color-") {
    // interactive settings element > status > state
    @each $interactive-key in $interactive-keys {
        @if map-has-key($settings, $interactive-key) {
            $interactive-settings: map-get($settings, $interactive-key);
            @each $status-key, $status-settings in $interactive-settings {
                @each $state-key, $values in $status-settings {
                    $name: nth($values, 1);
                    $fallback: nth($values, 2);
                    --#{$prefix}#{$interactive-key}-#{$status-key}-#{$state-key}: #{map-get($colors, $name)};
                    --#{$prefix}#{$interactive-key}-#{$status-key}-#{$state-key}--rgb: #{hexToRGB(
                            map-get($colors, $name)
                        )};
                    --#{$prefix}#{$interactive-key}-#{$status-key}-#{$state-key}--light: #{scale-color(
                            map-get($colors, $name),
                            $lightness: 35%
                        )};
                    --#{$prefix}#{$interactive-key}-#{$status-key}-#{$state-key}--dark: #{scale-color(
                            map-get($colors, $name),
                            $lightness: -2%
                        )};
                }
            }
        }
    }

    // static settings element > status
    @each $static-key in $static-keys {
        @if map-has-key($settings, $static-key) {
            $static-settings: map-get($settings, $static-key);
            @each $key, $values in $static-settings {
                $name: nth($values, 1);
                $fallback: nth($values, 2);
                --#{$prefix}#{$static-key}-#{$key}: var(--#{$value-prefix}#{$name});
                --#{$prefix}#{$static-key}-#{$key}--rgb: #{hexToRGB(map-get($colors, $name))};
                --#{$prefix}#{$static-key}-#{$key}--light: #{scale-color(map-get($colors, $name), $lightness: 35%)};
                --#{$prefix}#{$static-key}-#{$key}--dark: #{scale-color(map-get($colors, $name), $lightness: -2%)};
            }
        }
    }
}
