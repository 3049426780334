$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.tiny {
    composes: root;
    width: 14px;
    height: 14px;
}

.small {
    composes: root;
    width: 16px;
    height: 16px;
}

.medium {
    composes: root;
    composes: global(p-1);
}

.large {
    composes: root;
    width: 20px;
    height: 20px;
}

.extraLarge {
    composes: root;
    width: 24px;
    height: 24px;
}

.largest {
    composes: root;
    width: 48px;
    height: 48px;
}

.baseline {
    vertical-align: baseline;
}

.top {
    vertical-align: top;
}

.middle {
    vertical-align: middle;
}

.bottom {
    vertical-align: bottom;
}

.sub {
    vertical-align: sub;
}

.text-top {
    vertical-align: text-top;
}

.outline {
    path {
        fill: transparent;
        stroke: currentColor;
    }
}
