$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

// @see also SelectStyles.tsx

.container {
    composes: global(cy-1);
    color: var(--color-text-default);
    width: 100%;
}

.label {
    display: block;
}

.required {
    color: var(--color-text-critical);
}

.labelSecondary {
    color: var(--color-text-subtle);
    font-style: italic;
    font-weight: 400;
}
