$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.col-info {
    color: #9ba9ab;
    .title {
        margin: 0;
        color: rgba(0, 0, 0, 0.65);
    }
    .sub-title {
        font-size: 11px;
    }
}
.active-days {
    .col-content {
        text-align: right;
    }
}

// TODO temporarily keep legacy preview button styles pending redesign direction
.preview-wrapper {
    background-color: #7ebb36;
    border: 1px solid #7ebb36;
    border-radius: $border-radius;
    width: fit-content;
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;

    @include mq($until: 400px) {
        margin-right: 8px;
    }

    &:hover {
        background-color: #8cba55;
        border-color: #8cba55;
        color: #fff;
    }

    .preview {
        background-color: transparent !important;
        color: #fff !important;
        border-color: transparent !important;
        border-radius: initial;

        @include mq($until: 400px) {
            font-size: 13px;
        }

        &.with-membership {
            padding-left: 8px;
            padding-right: 4px;
            white-space: nowrap;
            margin-right: 0;
            border: none;
            border-radius: initial;
            transition: none;

            > span::after {
                content: " :";
            }
        }

        &:focus {
            outline: none;

            body:not(.safe-focus-removal) &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 0px;
                width: calc(100% + 0px);
                height: calc(100% + 0px);
                z-index: 1;
                border-radius: 6px;
                border: 2px solid var(--color-text-highlight);
            }
        }
    }

    .preview-membership.ant-select {
        max-width: 160px;
        position: relative;
        width: fit-content;
        background-color: transparent;
        padding: 2px 4px 2px 2px;

        &.ant-select-focused {
            body:not(.safe-focus-removal) &:before {
                position: absolute;
                content: "";
                left: 1px;
                top: 1px;
                width: calc(100% - 4px);
                height: calc(100% - 2px);
                z-index: 1;
                border-radius: 2px;
                border: 2px solid var(--color-text-highlight);
            }
        }

        .ant-select-selector {
            height: 24px;
            padding: 4px 8px;
            flex: none;
        }

        .ant-select-selection-item {
            line-height: 14px;

            @include mq($until: 400px) {
                max-width: 50px;
                padding-right: 14px;
                font-size: 12px;
            }
        }

        .ant-select-selection {
            padding-left: 10px;
            color: #fff;
            background-color: transparent;
            border-color: transparent;
            border-left: none;
            max-width: 100%;

            &-selected-value {
                padding-right: 5px;
                max-width: 100%;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                user-select: none;
            }
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.featured-title-label {
    color: #8c8c8c;
    line-height: 22px;
    position: absolute;
    top: 100%;
    left: 1px;
    margin-top: 10px;
    transition: opacity 150ms ease, transform 150ms ease;
    transform-origin: top;

    .has-error & {
        opacity: 0;
        transform: scaleY(0);
    }
}

.membership-prices {
    background-color: #fafafa;
    padding: 16px;

    h2 {
        font-size: 14pt;
    }
}

.preview-membership-options-popup {
    width: fit-content !important;
    max-width: 350px;
}

.modal-edit-service-form {
    width: 100%;

    .ant-transfer {
        width: 100%;
    }

    .ant-transfer-list {
        flex: 1 1 auto;
    }

    .ant-transfer-operation {
        flex: none;
    }
}
