$env: "production";

@import "core/styles/settings/media-query";
@import "core/styles/settings/spacing";
@import "core/styles/settings/grid";
@import "core/styles/settings/colors";
@import "core/styles/settings/container-spacing";
@import "core/styles/mixins/button";
@import "core/styles/mixins/card";
@import "core/styles/mixins/elevation";
@import "core/styles/mixins/focus";
@import "core/styles/mixins/form";
@import "core/styles/mixins/grid";
@import "core/styles/mixins/link";
@import "core/styles/mixins/list";
@import "core/styles/mixins/palette";
@import "core/styles/mixins/settings-to-props";
@import "core/styles/mixins/spacing";
@import "core/styles/mixins/visibility";
@import "core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "styles/settings/border-radius";
@import "styles/settings/card";
@import "styles/settings/depth";
@import "styles/settings/elevation";
@import "styles/settings/layout";
@import "styles/settings/palette";
@import "styles/settings/spacing";
@import "styles/settings/typography";

@import "./Antd-overrides";

:root {
    --theme-primary-color: #6b7c98;
    --theme-soft-color: #dbe4f3;
    --theme-hard-color: #4e5a6d;
    --theme-primary-hover-color: #8296b7;
}

::selection {
    background-color: var(--theme-primary-hover-color);
}

a {
    &:hover {
        // unset Ant's poorly scoped a:hover selector
        color: unset;
    }

    @media (hover: hover) {
        &:hover {
            color: var(--theme-primary-color);
        }
    }

    &:active {
        color: var(--theme-hard-color);
    }
}

main.row {
    margin-right: 0;
}

.modal-overlay {
    z-index: 2;
}

h1 {
    font-weight: 500;
    margin-left: 10px;
}

.listing-page__add {
    color: #fff;
    background-color: var(--theme-primary-color);
    text-decoration: none;
    padding: 10px;
    min-width: 60px;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.listing-page__item {
    padding: 10px;

    &:hover {
        background-color: #ebebeb;
    }

    a {
        text-decoration: none;
        color: #000;
        display: block;
    }
}
