$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.heading {
    composes: global(text-reset);
    composes: global(text-page-heading);
    composes: global(mt-7);
}

.body {
    composes: global(text-reset);
    composes: global(mt-2);
    color: var(--color-text-subtle);
    text-align: center;
}

.buttonsContainer {
    composes: global(mt-5);
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mq($until: sm) {
        @include cy(3);

        > * {
            min-width: 100%;
        }
    }

    @include mq(sm) {
        @include cx(3);
        width: auto;
        flex-wrap: nowrap;

        > * {
            min-width: 0;
        }
    }
}

.icon {
    flex: none;
}
