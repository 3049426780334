$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $z-edit-modal;
    pointer-events: none;
}

.position {
    width: 100%;
    top: $top-bar-height;
    position: absolute;
    right: 0;
    background-color: var(--color-surface-neutral);
    height: calc(100% - #{$top-bar-height});

    @include mq(lg) {
        width: calc(100% - #{$menu-width});
    }
}

.container {
    composes: global(px-7);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - #{$action-footer-height});
    pointer-events: auto;
}

.content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
}

// global to make content width style available to descendants
:global(.content--narrow) {
    max-width: $content-narrow;
}

:global(.content--standard) {
    max-width: $content-standard;
}

:global(.content--wide) {
    max-width: $content-wide;
}

.body {
    composes: global(mt-9);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
