$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    width: 100%;
}

.innerContainer {
    @include px(6);
    @include py(4);
    width: 100%;
    @include grid(
        (
            "gridGutters": (
                xs: (
                    x: 12px,
                    y: 12px,
                ),
            ),
        )
    );
    min-height: 84px;
}

.link {
    composes: innerContainer;
    color: var(--color-text-default);

    @media (hover: hover) {
        &:hover {
            color: var(--color-text-default);
            background-color: rgba(var(--color-action-secondary-hover--rgb), 0.4);
        }
    }
}

.detailsContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.heading {
    composes: global(text-subheading);
}

.subtle {
    color: var(--color-text-subtle);
}

.details {
    composes: global(list-reset);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.detailItem {
    white-space: nowrap;
    & + & {
        &:before {
            content: "•";
            display: inline;
            @include px(2);
        }
    }
}

.propertiesContainer {
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    composes: global(cx-4);
}

.propertyCol {
    white-space: nowrap;

    @include mq(md) {
        &:nth-child(1) {
            min-width: calc(55% - 0.5 * var(--spacing-4));
        }

        &:nth-child(2) {
            min-width: calc(45% - 0.5 * var(--spacing-4));
        }
    }
}

.badgeIcon {
    composes: global(mr-1);
}
