$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.container {
    width: 100%;
    outline: none;
    position: relative;

    @include safe-focus-removal {
        &:focus {
            outline: 2px solid var(--color-border-highlight-default);
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: transparent;
        z-index: -1;
        transition: all 0.2s ease-in-out;
    }
}

.containerDragging {
    composes: container;

    &::before {
        left: var(--spacing--3);
        width: calc(100% + 2 * var(--spacing-3));
        border-radius: var(--border-radius);
        background-color: var(--color-surface-default);
        @include elevation(large);
    }
}

.variantsList {
    composes: global(my--3); // offset first & last item padding
    width: 100%;
}

.variantContainer {
    display: flex;
    align-items: center;
    composes: global(py-3);
}

.gripCell {
    flex: none;
    composes: global(pt-8);
    composes: global(mr-4);
    align-self: flex-start;
}

.gripIcon {
    color: var(--color-icon-primary-default);
    width: 16px;
}

.fieldsContainer {
    flex: 1 1 auto;
    display: grid;
    column-gap: var(--spacing-4);
    row-gap: calc(var(--spacing-4) - 2px);
    align-items: end;
}

.variant--single--two {
    composes: fieldsContainer;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "cell1 cell2";
}

.variant--single--three {
    composes: fieldsContainer;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "cell1 cell1" "cell2 cell3";

    @include mq(xl) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "cell1 cell2 cell3";
    }
}

.variant--multi--two {
    composes: fieldsContainer;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "cell0 cell0" "cell1 cell2";

    @include mq(xl) {
        grid-template-columns: 1fr 120px 120px min-content;
        grid-template-areas: "cell0 cell1 cell2 cell4";

        .cell0_1 {
            display: none;
        }
    }
}

.variant--multi--three {
    composes: fieldsContainer;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "cell0 cell0" "cell1 cell1" "cell2 cell3";

    @include mq(xl) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "cell0 cell0 cell0" "cell1 cell2 cell3";
    }
}

//name + remove container
.cell0 {
    grid-area: cell0;
    display: flex;
    align-items: flex-end;
}

// name
.cell0_0 {
    flex: 1 1 auto;
}

// remove
.cell0_1 {
    composes: global(ml-4);
    flex: none;
}

//sku
.cell1 {
    grid-area: cell1;
}

//price
.cell2 {
    grid-area: cell2;
}

//energy
.cell3 {
    grid-area: cell3;
}

// alternate remove
.cell4 {
    grid-area: cell4;
    display: none;

    @include mq(xl) {
        @at-root .variant--multi--two & {
            display: block;
        }
    }
}

.subtle {
    color: var(--color-text-subtle);
}

.removeButton {
    min-height: 36px;
}
