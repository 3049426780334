$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.tips-input {
    display: block;

    // intentionally increasing specificity here to beat ant
    .tips-input-field {
        color: $color-cool-grey-90;
        ::placeholder {
            color: $color-cool-grey-60;
            opacity: 0.8;
        }

        &__wrapper {
            margin-bottom: 2px;
        }

        &__reset {
            font-weight: 500;
            appearance: none;
            border: none;
            display: inline-flex;
            background-color: transparent;
            color: $color-cool-grey-10;
            outline-color: $color-cool-grey-10;
            line-height: 24px;
            padding-left: 0;
            margin-top: 12px;

            &:hover {
                cursor: pointer;
                color: inherit;
            }
        }
    }
}
