$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.button {
    composes: global(cx-3);
}

.resultRow {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    composes: global(pt-9);
    composes: global(pb-6);
    composes: global(cy-6);
}

.icon {
    display: flex;
    width: 60px;
}

.successIcon {
    composes: icon;
    color: var(--color-icon-success-default);
}

.failIcon {
    composes: icon;
    color: var(--color-icon-critical-default);
}

.resultInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resultHeading {
    composes: global(text-page-heading);
    color: var(--color-text-default);
    text-align: center;
}

.badge {
    animation-duration: 0.25s;
    animation-name: badgeIn;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes badgeIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    85% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}
