$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    background-color: var(--color-surface-default);
    height: $top-bar-height;

    composes: global(pl-3);
    composes: global(pr-5);
}

.menu {
    composes: global(hide-on-large);
}

.logoContainer {
    composes: global(ml-3);
    composes: global(hide-until-large);
}

.logo {
    height: 100%;
    flex: none;
    width: 36px;
    display: flex;
    align-items: center;
    color: var(--color-action-primary-default);
}

.locationLargeScreens {
    composes: global(ml-5);
    composes: global(hide-until-large);
}

.regionLargeScreens {
    composes: global(hide-until-large);
}

.badges {
    display: flex;
    composes: global(ml-3);

    @include mq(md) {
        @include ml(5);
    }
    composes: global(cx-4);
}

.publish {
    composes: global(cx-3);
    margin-left: auto;
    display: flex;
    align-items: center;
}

.menuContainerSmallScreens {
    composes: global(hide-on-large);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $top-bar-height;
    pointer-events: none;

    @at-root :global(.menu-active) & {
        pointer-events: auto;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(var(--color-surface-darken--rgb), 0.45);
            z-index: 1;
        }
    }
}

// top bar menu containing location and region selectors on small screens
.menuSmallScreens {
    composes: global(hide-on-large);
    composes: global(cx-5);
    composes: global(pl-3);
    composes: global(pr-6);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    width: $menu-width;
    height: $top-bar-height;
    background-color: var(--color-surface-default);
    transform: translateX(-100%);
    visibility: hidden;
    flex: none;
    transition: all 0.2s;
    z-index: 2;

    @at-root :global(.menu-active) & {
        transform: translateX(0);
        visibility: visible;
    }
}

.locationSmallScreens {
    width: 176px;
}
