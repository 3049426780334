$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.ant-alert {
    &.no-active-service-section {
        border-color: #fff7f6;
        background-color: #fff7f6;
        margin-bottom: 10px;

        .ant-alert-message {
            color: #ef465c;
        }

        .ant-alert-icon {
            color: #ef465c;
        }
    }

    &.viewing-as {
        border-color: #909eb9;
        background-color: #909eb9;
        margin-bottom: 10px;

        .ant-alert-message {
            color: white;
        }

        .ant-alert-icon {
            color: white;
        }
    }
}

.content__col--services {
    .item-wrapper-outer {
        overflow: hidden;
    }
}

.service-list {
    &__venue-time {
        color: #999999;
        margin-left: 10px;
    }

    &-item {
        &__header {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 6px;
            overflow: hidden;
        }

        &__live {
            margin-right: 12px;

            .is-preview & {
                background-color: #909eb9;
            }
        }

        &__active-time,
        &__active-date {
            display: flex;
            align-items: center;
            margin-right: 12px;

            svg {
                display: block;
                margin: 0 4px;
            }
        }

        &__active-date {
            color: #b3b3b3;
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            flex-wrap: wrap;

            .break {
                flex-basis: 100%;
                height: 10px;
            }
        }

        &__sections,
        &__priceLists {
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        &__section,
        &__surcharge,
        &__priceList {
            font-size: 8px;
            font-weight: 500;
            line-height: 19px;
            padding: 0 8px;
            color: white;
            background: #000000;
            border-radius: 50px;
            margin-right: 4px;
            border: 1px solid #000000;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                display: block;
                margin-right: 4px;
                font-size: 10px;
            }
        }

        &__sections {
            flex-wrap: wrap;
            margin-left: -4px;
            margin-top: -4px;
        }

        &__section {
            :first-child {
                flex: none;
            }
            margin-left: 4px;
            margin-top: 4px;
            margin-right: 0;
            white-space: nowrap;
            overflow: hidden;
            flex-basis: 1 1 0;
        }

        &__surcharge {
            background: white;
            color: #000000;
            margin-right: 12px;
            font-weight: 600;
        }

        &__priceList {
            color: #000000;
            background: #e8e8e8;
            border: 1px solid #e8e8e8;
            font-weight: 600;
        }
    }
}

.live-services-list {
    &.list-container {
        &.ant-list-bordered {
            .list-item {
                border-bottom: 1px solid #ef465c;
            }
        }

        .list-item {
            border: 1px solid #ef465c;
        }

        &.is-preview {
            &.ant-list-bordered {
                .list-item {
                    border-bottom: 1px solid #909eb9;
                }
            }

            .list-item {
                border: 1px solid #909eb9;
            }
        }

        .ant-list-empty-text {
            display: none;
        }
    }
}

.list-services-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    // TODO remove legacy Services support https://meanduapp.atlassian.net/browse/ROCK-745
    &__list {
        &--legacy {
            padding: 24px;
            flex: 1 1;
            overflow-y: auto;
        }

        &--new {
            align-self: center;
            max-width: 750px;
            width: 100%;

            @include mq(xl) {
                min-width: 750px;
            }

            .select-datalisting-filter {
                margin-bottom: 10px;
            }
        }
    }

    .list-container {
        .list-item {
            .item-wrapper-outer {
                overflow: hidden;
            }

            &__disabled {
                color: #b3b3b3;
                background: #fafafa;

                &:hover,
                &.list-item__active {
                    background: var(--theme-soft-color);
                }

                &.list-item__active:not(.anticon) {
                    color: #b3b3b3;
                }

                .service-list {
                    &-item {
                        &__section,
                        &__surcharge {
                            background: #b3b3b3;
                            border: 1px solid #b3b3b3;
                        }

                        &__surcharge {
                            background: #fafafa;
                            color: #b3b3b3;
                        }
                    }
                }
            }
        }
    }

    &__footer {
        padding: 14px 24px;
        background: #000000;
        font-size: 14px;
        line-height: 125%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
            color: white;
        }

        .ant-calendar-picker {
            min-width: 134px !important;
            width: 134px;
            position: relative;
            background: white;
            border-radius: 4px;
            cursor: pointer;

            &::before {
                content: "Preview date";
                position: absolute;
                left: 36px;
                top: 50%;
                transform: translateY(-50%);
                color: #000000;
            }

            input {
                padding-left: 36px;
                color: transparent;
                background: transparent;
                cursor: pointer;

                &::placeholder {
                    color: #000000;
                }
            }

            .calendar-icon {
                display: block;
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
            }

            .ant-calendar-picker-clear {
                opacity: 1;
                width: calc(100% - 48px);
                display: flex;
                justify-content: flex-end;

                &::after {
                    content: "Clear date";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #000000;
                }
            }
        }
    }
}
