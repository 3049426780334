$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.data-listing-page {
    .ant-collapse {
        background-color: transparent;
        border: 0;

        > .ant-collapse-item {
            border: 0;
            &:last-child {
                border-radius: 4px;
                .ant-collapse-header {
                    border-radius: 4px;
                }
            }
        }
    }

    .ant-collapse-header {
        background-color: darken(#f0f0f0, 3%);
        border-radius: 4px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .ant-collapse-content {
        background-color: transparent;
        border: 0;

        > .ant-collapse-content-box {
            padding: 0;

            .list-container {
                margin-top: 0;
            }
        }
    }

    &__section-title {
        display: inline-flex;
        align-items: center;
    }

    &__item-count {
        display: inline-flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.85);
        color: #e5e5e5;
        padding: 0 8px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 4px;
        margin-left: 10px;
    }

    .listing-col-right {
        display: flex;
        align-items: baseline;

        > div {
            font-size: 14px;
            padding-right: 10px;
        }
    }
}
