$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.message {
    composes: global(text-reset);
}

.confirmRow {
    composes: row;
    composes: global(cy-4);

    p {
        margin: 0;
    }

    p + ul {
        @include mt(1);
    }

    ul + p {
        @include mt(3);
    }
}

.successRow {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    composes: global(pt-9);
    composes: global(pb-6);
    composes: global(cy-6);
}

.successIcon {
    color: var(--color-icon-success-default);
    display: flex;
    width: 60px;
}

.successInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.successHeading {
    composes: global(text-page-heading);
    composes: global(text-reset);
}

.successSummary {
    text-align: center;
}
