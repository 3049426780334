$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.nestedModifiersList {
    @include listReset;
    @include pb(4);
    width: 100%;
}

.nestedModifiersListItem {
    overflow: hidden;

    & + & {
        border-top: 1px solid var(--color-border-primary-subtle);
    }
}

.nestedModifiersListItemExit {
    animation-duration: var(--exit-animation-duration);
    animation-name: slideout;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes slideout {
    0% {
        height: 64px;
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }

    50% {
        height: 64px;
        scale: 0;
        transform: scale(0);
    }

    100% {
        height: 0;
        transform: scale(0);
    }
}
