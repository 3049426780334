$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.round-checkbox {
    .ant-checkbox-group-item {
        position: relative;
        width: 24px;

        .ant-checkbox-inner {
            display: none;
        }

        span:not(.ant-checkbox) {
            background-color: #e5e5e5;
            color: #ababab;
            border-radius: 50%;
            cursor: pointer;
            left: 0;
            top: 0;
            text-align: center;
            position: absolute;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 11px;
            padding: 0;
        }

        span:not(.ant-checkbox):hover {
            background-color: rgba(229, 229, 229, 0.7);
        }

        .ant-checkbox input[type="checkbox"] {
            visibility: hidden;
        }

        &.ant-checkbox-wrapper-checked {
            span:not(.ant-checkbox) {
                background-color: #687687;
                color: white;
            }

            span:not(.ant-checkbox):hover {
                background-color: rgba(104, 118, 135, 0.7);
            }

            span:not(.ant-checkbox):after {
                opacity: 1;
            }
        }
    }
}
