$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.root {
    width: 100%;
    display: flex;
    align-items: center;

    flex-direction: column;

    @include mq(xl) {
        flex-direction: row;
    }
}

.meta {
    min-width: 100%;
    composes: global(mb-3);
    text-align: center;
    font-size: 14px;

    @include mq(xl) {
        text-align: left;
        min-width: 0;
        flex: 1 1 0;
        margin-bottom: 0;
    }
}

.after {
    display: none;

    @include mq(xl) {
        display: block;
        flex: 1 1 0;
    }
}

.button {
    min-width: 24px;
    height: 24px;
    padding: 2px;
    font-size: 14px;
    line-height: 14px;

    @include mq(sm) {
        padding: 6px;
        min-width: 32px;
        height: 32px;
    }
}

.buttonDisabled {
    composes: button;
    background-color: transparent !important;
}

.gap {
    composes: button;
    &::before {
        content: "⋯";
    }
}

.gapBefore {
    composes: gap;
    @media (hover: hover) {
        &:hover {
            &::before {
                content: "⪻";
            }
        }
    }
}

.gapAfter {
    composes: gap;
    @media (hover: hover) {
        &:hover {
            &::before {
                content: "⪼";
            }
        }
    }
}

.currentPage {
    composes: button;
    color: var(--color-action-primary-default);
    border: 1px solid currentColor;
}

.buttons {
    composes: global(cx-3);
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex: none;
}

.iconPrev {
    transform: rotate(90deg);
}

.iconNext {
    transform: rotate(-90deg);
}

.selectContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    max-width: 140px;
}
