$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(px-7);
    background-color: var(--color-surface-default);
    height: $action-footer-height;
    box-shadow: inset 0px 1px 0px var(--color-border-primary-subtle);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    transition: background-color 0.2s;

    // use hidden scroll bar to align with content
    &::-webkit-scrollbar {
        background-color: transparent;
    }

    @at-root :global(.content-has-scroll) & {
        overflow-y: scroll;
    }
}

.containerFixed {
    composes: container;
    position: fixed;

    @include mq(lg) {
        width: calc(100% - #{$menu-width});
    }
}

.innerContainer {
    composes: global(cx-3);
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

:global(.content--narrow) .innerContainer {
    max-width: $content-narrow;
}

:global(.content--standard) .innerContainer {
    max-width: $content-standard;
}

:global(.content--wide) .innerContainer {
    max-width: $content-wide;
}

.alignLeft {
    justify-content: flex-start;
}

.alignRight {
    justify-content: flex-end;
}

.containerDirty {
    composes: container;
    color: var(--color-text-white);
    background-color: var(--color-cool-gray-090);
    box-shadow: inset 0px 1px 0px var(--color-border-primary-subtle);
}

.message {
    composes: global(text-subheading);

    .alignRight & {
        margin-left: auto;
    }

    .alignLeft & {
        margin-right: auto;
    }
}
