$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.container {
    width: 100%;
    outline: none;
    position: relative;

    @include safe-focus-removal {
        &:focus {
            outline: 2px solid var(--color-border-highlight-default);
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: transparent;
        z-index: -1;
        transition: all 0.2s ease-in-out;
    }
}

.modifierOuterContainer {
    composes: global(py-3);
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: calc(100% + var(--spacing-6) * 2);
        left: var(--spacing--6);
        height: 1px;
        background-color: var(--color-border-primary-subtle);
        bottom: 0;

        @include mq(md) {
            width: 100%;
            left: 0;
        }
    }
}

.modifierContainer {
    display: flex;
    align-items: center;
}

// marker for no variants don't delete
.modifierContainerNoVariants {
    display: flex;
}

// marker for drag disabled don't delete
.modifierContainerNoDrag {
    display: flex;
}

.errorContainer {
    composes: global(pt-3);
    @include mq(md) {
        @include pt(2);
    }
    padding-left: calc(var(--spacing-4) + var(--spacing-5));
}

.containerDragging {
    composes: container;

    &::before {
        left: var(--spacing--3);
        width: calc(100% + 2 * var(--spacing-3));
        border-radius: var(--border-radius);
        background-color: var(--color-surface-default);
        @include elevation(large);
    }

    .modifierOuterContainer {
        &:before {
            display: none;
        }
    }
}

.modifiersList {
    composes: global(mt--3); // offset item padding
    width: 100%;
}

.gripCell {
    flex: none;
    composes: global(mr-4);
}

.gripIcon {
    color: var(--color-icon-primary-default);
    width: 16px;
    flex-wrap: wrap;
}

.cellContainer {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    composes: global(cx-3);
    flex-wrap: wrap;
    min-width: 0;

    @include mq(md) {
        flex-wrap: nowrap;
    }
}

.nameCell {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    composes: global(mb-3);

    @include mq(md) {
        @include mb(0);
        @include mr(3);
        min-width: 0;
    }

    .modifierContainerNoVariants & {
        @include mq($until: md) {
            @include mb(0);
            min-width: 0;
            // 100% - (icon btn width + their margin) * 2
            max-width: calc(100% - (36px + var(--spacing-3)) * 2);
        }
    }
}

.required {
    composes: global(warning);
    composes: global(ml-3);
}

.nameCellPrimary {
    composes: global(text-subheading);
    display: flex;
}

.nameCellPrimaryName {
    max-width: calc(100% - 16px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.nameCellSecondary {
    color: var(--color-text-subtle);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include mq($until: md) {
        display: none;
    }
}

.selectCell {
    flex: 1 1 0;
    margin-left: 0;

    @include mq(md) {
        flex: none;
        width: 200px;
    }

    [id$="placeholder"] {
        pointer-events: none;
    }
}

.iconCell {
    flex: none;
}

.iconButton {
    min-height: 36px;
}
