@mixin linkReset {
    display: inline;
    border: none;
    text-decoration: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    text-align: center;
    appearance: none;
    font: inherit;
    height: auto;
}
