$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.monthNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuButton {
    @include cx(2);
}

.iconPrev {
    transform: rotate(90deg);
}

.iconNext {
    transform: rotate(-90deg);
}

.monthsList {
    @include listReset;
    @include px(4);
    background-color: var(--color-surface-default);
    position: absolute;
    display: grid;
    width: 100%;
    height: calc(100% - 47px);
    left: 0;
    top: 47px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    z-index: 10;
}

.monthButton {
    composes: global(button-reset);
    composes: global(text-button);
    border-radius: 20px;
}

.monthButtonActive {
    composes: monthButton;
    background-color: var(--color-interactive-primary-default);
    border-color: var(--color-interactive-primary-default);
    color: var(--color-text-white);

    @media (hover: hover) {
        &:hover {
            background-color: var(--color-interactive-primary-hover);
            border-color: var(--color-interactive-primary-hover);
            color: var(--color-text-white);
        }
    }

    &:active {
        background-color: var(--color-interactive-primary-pressed);
        border-color: var(--color-interactive-primary-pressed);
        color: var(--color-text-white);
    }
}
