$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.radio--container {
    .ant-radio-wrapper {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        white-space: normal;

        span {
            user-select: none;
        }

        &:hover .ant-radio,
        &:hover .ant-radio-inner,
        &:focus + .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner {
            border-color: var(--theme-primary-color);
        }

        &.ant-radio-wrapper-checked {
            .ant-radio-inner {
                border-color: var(--theme-primary-color);

                &:after {
                    background-color: var(--theme-primary-color);
                }
            }

            .ant-radio-checked:after {
                border-color: var(--theme-primary-color);
            }
        }
    }
}
