$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.list-image-placeholder {
    border-radius: $border-radius-normal;
    background-color: $color-grey-4;
    color: $color-grey-7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}

.list-image-wrapper-inline {
    width: 70px;
    margin-right: 24px;
    justify-content: center;

    img,
    .list-image-placeholder {
        border-radius: $border-radius-normal;
        width: 70px;
        height: 50px;
        display: flex;
        object-fit: cover;
    }

    .image-overlay {
        border-radius: $border-radius-normal;
        width: 72px;
        height: 50px;
    }

    &.loaded {
        .image-overlay {
            animation: fadeOut 150ms ease;
            opacity: 0;
        }
    }
}

.list-image-wrapper-thumb {
    width: 90px;
    justify-content: center;
    background-color: $color-grey-3;
    border-radius: $border-radius-normal;
    z-index: 2;

    img,
    .image-overlay,
    .list-image-placeholder {
        border-radius: $border-radius-normal;
        width: 90px;
        height: 60px;
        display: flex;
        object-fit: cover;
    }

    .image-overlay {
        border-radius: $border-radius-normal;
        width: 92px;
        height: 60px;
    }

    &.loaded {
        .image-overlay {
            animation: fadeOut 150ms ease;
            opacity: 0;
        }
    }
}

.image-overlay {
    position: absolute;
    z-index: 1;
    background-color: $color-grey-3;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
}
