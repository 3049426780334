$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.content__col.content__col__dailysalesreportpage {
    overflow: hidden;

    .content__col-list {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

.daily-sales-report-page {
    flex: 1 1;
    display: flex;
    flex-direction: column;

    .ant-select {
        min-width: 150px;
    }

    .table-container {
        position: relative;
        flex-grow: 1;
        padding-bottom: 54px;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 1200px;

        > * {
            min-width: 700px;
        }
    }

    .ant-table-footer {
        border-radius: 0;
        padding: 0;

        overflow-y: scroll;
    }

    .ant-table-tfoot > tr > td {
        background: #fafafa;
        transition: background 0.3s ease;
        text-align: left;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;

        padding: 16px 16px;
    }

    .ant-table-thead > tr > th {
        overflow-wrap: normal;
    }

    &__header-label {
        display: block;
    }

    &__header-label--small {
        display: block;
        margin-top: -0.2em;
        font-size: 0.8em;
        white-space: nowrap;
    }
}

.ant-row.report-date-filter {
    flex-wrap: nowrap;
    min-width: 480px;

    > * + * {
        margin-left: 16px;
    }

    label {
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        display: block;
    }

    .filter-input {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.report-date-filter__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
}
