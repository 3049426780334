$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.root {
    transition: height 0.2s ease-in;
    overflow: hidden;
    width: 100%;
}

.card {
    border-radius: var(--border-radius);
}

.container {
    @include p(5);
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: min-content min-content;
    column-gap: var(--spacing-5);
    row-gap: var(--spacing-2);
}

.noChildren {
    @include py(3);
    column-gap: 10px;
    row-gap: 0;
}

.default--info {
    composes: card;
    background-color: var(--color-surface-neutral);
    border: 1px solid var(--color-border-primary-default);
    color: var(--color-text-default);

    :is(.closeButton, .icon) {
        color: var(--color-icon-primary-default);
    }
}

.default--success {
    composes: card;
    background-color: var(--color-surface-success);
    border: 1px solid var(--color-border-success-default);
    color: var(--color-text-success);

    :is(.closeButton, .icon) {
        color: var(--color-icon-success-default);
    }
}

.default--warning {
    composes: card;
    background-color: var(--color-surface-critical);
    border: 1px solid var(--color-border-critical-default);
    color: var(--color-text-critical);

    :is(.closeButton, .icon) {
        color: var(--color-icon-critical-default);
    }
}

.strong {
    color: var(--color-text-white);

    :is(.closeButton, .icon) {
        color: var(--color-icon-white-default);
    }
}

.strong--info {
    composes: card;
    composes: strong;
    background-color: var(--color-surface-neutral-strong);
    border: 1px solid var(--color-surface-neutral-strong);
}

.strong--success {
    composes: card;
    composes: strong;
    background-color: var(--color-surface-success-strong);
    border: 1px solid var(--color-surface-success-strong);
}

.strong--warning {
    composes: card;
    composes: strong;
    background-color: var(--color-surface-critical-strong);
    border: 1px solid var(--color-surface-critical-strong);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.title {
    composes: global(text-reset);
    color: inherit;
}

.titleLarge {
    composes: title;
    composes: global(text-heading);
}

.titleSmall {
    composes: title;
    composes: global(text-body);
}

.body {
    grid-column-start: 2;
}

.icon {
    align-self: start;

    .noChildren & {
        margin-top: 2px;
    }
}

.closeButton {
    margin-top: 2px;

    .noChildren & {
        margin-top: 0;
    }
}
