$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";
.content--container {
    div.content__col--surcharges {
        overflow-y: hidden;

        div.surcharges-page {
            .ant-table-container::after {
                display: none;
            }

            &__body {
                margin-top: 32px;

                &__title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-grey-9;
                }

                &__description {
                    font-size: 14px;
                    line-height: 22px;
                    color: $color-grey-7;
                }

                &__recurring-table {
                    margin: 20px 0;
                }

                .ant-tabs-bar {
                    border-bottom: none;
                }

                table {
                    border-collapse: collapse;
                }

                table,
                .ant-table-small,
                th,
                td {
                    border: none;
                    padding-top: 11px;
                }

                th,
                thead tr {
                    background-color: transparent;

                    span {
                        white-space: nowrap;
                    }
                }

                tbody > tr {
                    border: 1px solid #e8e8e8;
                }

                .ant-table-wrapper {
                    .ant-table,
                    .ant-picker-input,
                    .ant-input {
                        color: $color-grey-9;
                    }

                    .ant-table-content {
                        max-width: 810px;
                    }
                }

                .ant-table-wrapper.with-surcharge-id {
                    div.ant-table-content {
                        max-width: 900px;
                    }
                }

                .ant-table-small > .ant-table-content {
                    margin: 0 16px 0 0;
                }

                .ant-table .ant-table-content > table > .ant-table-thead tr,
                .ant-table .ant-table-content > table > .ant-table-tbody tr {
                    &.row-empty {
                        td,
                        td span,
                        td input,
                        td svg {
                            color: #bfbfbf;
                        }

                        .col-actions {
                            cursor: default;
                        }
                    }

                    &.row-live {
                        .ant-input-disabled,
                        .ant-picker-input[disabled] {
                            color: $color-grey-9;
                            background-color: $color-grey-1;
                        }

                        .ant-checkbox-disabled + span {
                            color: $color-grey-9;
                        }

                        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
                            color: $color-grey-1;
                            border-color: $color-grey-3;
                        }
                    }

                    td {
                        padding: 11px 8px 4px;

                        .has-error .ant-input-affix-wrapper,
                        .has-error .ant-input-affix-wrapper:hover,
                        .has-error__start .timerange-picker__start input,
                        .has-error__end .timerange-picker__end input,
                        .has-error .ant-checkbox-inner,
                        .has-error__start .timerange-picker__start input:hover,
                        .has-error__end .timerange-picker__end input:hover {
                            border-color: $color-gold-3;
                        }

                        .has-error .ant-input-group-addon {
                            border-color: $color-gold-3;
                            color: $color-gold-3;
                        }

                        .has-error .ant-input:focus {
                            -webkit-box-shadow: 0 0 0 2px rgba(255, 179, 0, 0.2);
                            box-shadow: 0 0 0 2px rgba(255, 179, 0, 0.2);
                        }

                        .row-error {
                            color: $color-gold-3;
                            line-height: 22px;
                            height: 22px;

                            > span {
                                svg {
                                    margin-right: 11px;
                                }

                                font-size: 14px;
                                position: absolute;
                                display: flex;
                                align-items: center;
                                z-index: 1;
                            }
                        }

                        .ant-checkbox-wrapper {
                            margin-top: 6px;
                        }
                    }

                    th:first-child,
                    td:first-child {
                        padding-left: 16px;
                    }
                }

                tr.ant-table-row td {
                    white-space: nowrap;
                    vertical-align: top;
                }

                .ant-table-thead th {
                    font-weight: bold;
                }
                //##########
            }

            .col {
                &-day {
                    min-width: 184px;

                    &__date {
                        line-height: 22px;
                        margin-bottom: 12px;

                        .indicator {
                            width: 47px;
                            height: 18px;
                            color: $color-grey-1 !important;
                        }
                    }
                }

                &-day,
                &-duration {
                    &__label {
                        line-height: 22px;
                        margin: 5px 0 12px;
                    }
                }

                &-input {
                    z-index: 1; // ensure error over other cells
                    .ant-input-affix-wrapper {
                        min-width: 80px;
                    }
                }

                &-actions {
                    text-align: center;

                    .anticon {
                        margin-top: 8px;

                        svg {
                            height: 16px;
                            width: 16px;
                            color: $color-grey-7;
                        }
                    }

                    span:hover {
                        cursor: pointer;

                        .anticon svg {
                            color: $color-grey-8;
                        }
                    }

                    .disabled:hover {
                        cursor: not-allowed;

                        .anticon svg {
                            color: $color-grey-7;
                        }
                    }
                }
            }

            .input-percentage {
                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                /* Firefox */
                input[type="number"] {
                    -moz-appearance: textfield;
                }
            }

            .edit-form--commands {
                margin-left: -24px;

                &__warning {
                    font-size: 14px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    color: $color-gold-3;
                }
            }
        }
    }
}
