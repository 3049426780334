$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.controls {
    composes: global(cx-8);
    composes: global(mb-6);
}

.tab {
    composes: global(button-reset);
    composes: global(text-heading);
    composes: global(py-5);
    color: var(--color-surface-neutral-strong);
    position: relative;
    outline: none;

    @include safe-focus-removal {
        &:focus:not(:active):not(.disabled) {
            &:before {
                position: absolute;
                content: '';
                left: 50%;
                top: 50%;
                width: calc(100% + 8px);
                height: 32px;
                border: 2px solid var(--color-border-highlight-default);
                transform: translate(-50%,-50%);
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            color: var(--color-action-primary-hover);
        }
    }
    
    &:active {
        color: var(--color-action-primary-pressed);
    }
}

.tabActive {
    composes: tab;
    color: var(--color-action-primary-default);

    &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--color-action-primary-default);
    }
}

.tabPanel {

}

.tabPanelHidden {
    display: none;
}