$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.dailysales__details {
    &__modal {
        .ant-modal-body {
            padding: 30px 24px 24px 24px;
        }

        .ant-modal-header {
            padding: 30px 60px 5px 24px;
            border-bottom: none;
        }

        .ant-modal-title {
            color: #4f5660;
            font-size: 24px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-items: flex-start;

        max-height: 70vh;
    }

    &__figures {
        margin-bottom: 30px;

        th,
        & td {
            border: 1px solid #e3e5e8;
            text-align: right;
            padding: 10px;
        }

        th {
            &:first-child {
                width: 45%;
            }
            background: #f8f9f9;
        }

        td {
            text-align: right;
        }

        &__refunds {
            tr:first-child {
                color: #f00;
            }
        }
    }

    &__remittance {
        overflow-y: auto;
        border-top: 1px solid #e3e5e8;
        border-bottom: 1px solid #e3e5e8;

        table {
            border-collapse: collapse;
            width: 100%;
        }

        th,
        & td {
            border: 1px solid #e3e5e8;
            padding: 16px;
            font-size: 14px;
        }

        th {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }

            border-top: none;
            border-bottom: none;
            text-align: center;
            background: #f8f9f9;
            position: sticky;
            top: 0;
            background-clip: padding-box;
        }

        td {
            border-top: none;
            text-align: right;
        }

        tbody tr:last-child td {
            border-bottom: none;
        }
    }
}

@media screen and (max-height: 660px) {
    .ant-modal.dailysales__details__modal {
        top: 0px;
        padding-bottom: 0px;

        .ant-modal-content {
            height: 100vh;
        }

        .ant-modal-body {
            overflow-y: auto;
            height: calc(100vh - 57px);
        }

        .dailysales__details__content {
            overflow-y: visible;
        }

        .dailysales__details__remittance {
            overflow-y: visible;

            th {
                position: static;
            }
        }
    }
}
