$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.group-tabs__tree-wrapper {
    border: 1px solid $color-grey-4;
    padding: 12px;
    margin-top: 18px;
    min-height: 220px;

    @at-root .ant-form-item-has-error & {
        border-color: $color-red;

        // turn off error border on search input
        .ant-input-affix-wrapper {
            border-color: $color-grey-5 !important;
        }
    }

    .ant-tree-title {
        color: $color-grey-8;
        i {
            color: $color-grey-6;
        }
        .raw-title {
            opacity: 0.6;
        }
    }

    .site-tree-search-value {
        background-color: #ffe7ba;
    }

    .group-tabs__tree {
        padding-top: 7px;
    }
}
