$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.container {
    flex: none;
    transition: all 0.2s;
    width: $menu-width;
    height: 100%;
    z-index: 2;

    position: absolute;
    transform: translateX(-100%);
    visibility: hidden;

    @at-root :global(.menu-active) & {
        transform: translateX(0);
        visibility: visible;
    }

    @include mq(lg) {
        transform: translateX(0);
        position: static;
        visibility: visible;
    }
}

.nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--color-surface-background);
    color: var(--color-text-default);
}

.innerContainer {
    composes: global(pt-7);
    composes: global(px-5);
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid var(--color-border-menu-default);
}

.menu {
    composes: global(list-reset);
    composes: global(cy-2);
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    scrollbar-width: none; // hide on FF

    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
}

// clickable things
.interactiveItem {
    composes: global(px-5);
    composes: global(py-3);
    border-radius: $border-radius;
    position: relative;
    outline: none;

    @media (hover: hover) {
        &:hover {
            background-color: rgba(var(--color-border-menu-default--rgb), 0.5);
        }
    }

    @include safe-focus-removal {
        &:focus:not(:active):not(.disabled) {
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid var(--color-border-highlight-default);
                border-radius: $border-radius;
            }
        }
    }
}

.itemText {
    composes: global(text-subheading);
    font-size: 14px;
}

// base for submenu buttons and menu items
.item {
    composes: interactiveItem;
    composes: itemText;
}

.itemLevel0 {
    composes: global(px-5);
    composes: global(pt-6);
    composes: global(pb-3);
    composes: itemText;
    justify-content: flex-start;
    color: var(--color-text-subtle);
    width: 100%;
}

.itemLevel1 {
    composes: item;
    composes: global(button-reset);
    composes: global(px-3);
    composes: global(cx-2);
    justify-content: flex-start;
    width: 100%;
}

.itemLevel2 {
    composes: item;
    composes: global(mt-2);
    composes: global(pr-5);
    padding-left: 44px;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover {
        background-color: rgba(var(--color-action-secondary-pressed--rgb), 0.5);
    }
}

.label {
    composes: global(cx-2);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    user-select: none;
}

.labelLevel1 {
    composes: label;
    color: var(--color-text-default);
}

.labelLevel2 {
    composes: label;
    color: var(--color-text-subtle);
}

.iconLevel1 {
    composes: global(mr-3);
}

.iconLevel2 {
    display: inline-flex;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.submenu {
    composes: global(list-reset);
}

.submenuArrow {
    transition: all 0.1s ease;
    flex: none;
    margin-left: auto;
}
.submenuArrowCollapsed {
    composes: submenuArrow;
    transform: rotate(180deg);
}

.submenuArrowExpanded {
    composes: submenuArrow;
    transform: 0;
}

.submenuContent {
    composes: global(list-reset);
    overflow: hidden;
    transition: 0.15s ease;
}

.legacyItemLabel {
    opacity: 0.6;
}

.linkActive {
    .labelLevel2 {
        color: var(--color-interactive-primary-default);
    }
}

.prominentContainer {
    composes: global(px-4);
    composes: global(pb-5);
    composes: global(cx-2);
}

.prominentLink {
    composes: itemText;
    composes: global(py-3);
    composes: global(px-5);
    display: flex;
    align-items: center;
    color: $white;
    background-color: $black;
    border-radius: $border-radius;
    position: relative;

    @media (hover: hover) {
        &:hover {
            color: $white;
            background-color: $gray-090;
        }
    }

    @include safe-focus-removal {
        &:focus:not(:active):not(.disabled) {
            outline-color: $black;
            outline-width: 2px;
            outline-offset: 2px;
            outline-style: auto;
        }
    }
}

.externalLinkIcon {
    color: inherit;
}
