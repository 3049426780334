$labels-bg-color: $white;
$labels-cover-bg-color: $labels-bg-color; // change colour to debug labels cover

$z-loading-overlay: 100;
$z-grid-navigation: 40;
$z-header: 30;
$z-xlabel-cover: 25;
$z-ylabels-container: 20;
$z-xlabels-container: 15;
$z-service-details: 12;
$z-currentTime: 10;

$xlabels-container-height: 40px;

$ylabels-container-width: 57px;
$ylabel-offset: -8px;
$ylabel-double-offset: $ylabel-offset * 2;

$grid-inc-y: 53px;
$grid-double-inc-y: $grid-inc-y * 2;
$grid-min-column-width: 150px;
