$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.spinner {
    animation: loading-spinner 600ms linear infinite;
    display: inline-flex;
}

.icon {
    border: solid 2px transparent;
    border-top-color: currentColor;
    border-radius: 50%;
}

.icon--default {
    width: 16px;
    height: 16px;
    composes: icon;
}

.icon--medium {
    composes: icon;
    width: 48px;
    height: 48px;
    border-width: 4px;
}

.icon--large {
    composes: icon;
    width: 64px;
    height: 64px;
    border-width: 4px;
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
