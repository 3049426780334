$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.root {
    display: inline-flex;
    background-color: var(--color-surface-neutral);
    width: 100%;
    height: 100%;
    position: relative;
    pointer-events: none;
}

.image {
    object-fit: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.placeholder,
.overlay {
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: var(--color-icon-primary-subtle);
}

.overlay {
    background-color: var(--color-surface-neutral);
}

.overlayLoaded {
    composes: overlay;
    transition: opacity 150ms ease;
    opacity: 0;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
}
