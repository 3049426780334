/* 
 * These mixins are provided as a last resort when it is not possible to use a spacing class
 * Use sparingly!
 */
@function spacingVar($n) {
    @return var(--spacing-#{$n});
}

@mixin p($val) {
    padding: spacingVar($val);
}

@mixin pt($val) {
    padding-top: spacingVar($val);
}

@mixin pr($val) {
    padding-right: spacingVar($val);
}

@mixin pb($val) {
    padding-bottom: spacingVar($val);
}

@mixin pl($val) {
    padding-left: spacingVar($val);
}

@mixin px($val) {
    padding-left: spacingVar($val);
    padding-right: spacingVar($val);
}

@mixin py($val) {
    padding-top: spacingVar($val);
    padding-bottom: spacingVar($val);
}

@mixin m($val) {
    margin: spacingVar($val);
}

@mixin mt($val) {
    margin-top: spacingVar($val);
}

@mixin mr($val) {
    margin-right: spacingVar($val);
}

@mixin mb($val) {
    margin-bottom: spacingVar($val);
}

@mixin ml($val) {
    margin-left: spacingVar($val);
}

@mixin mx($val) {
    margin-left: spacingVar($val);
    margin-right: spacingVar($val);
}

@mixin my($val) {
    margin-top: spacingVar($val);
    margin-bottom: spacingVar($val);
}

@mixin cx($val) {
    > * + * {
        margin-left: spacingVar($val);
    }
}

@mixin cy($val) {
    > * + * {
        margin-top: spacingVar($val);
    }
}
