$env: "production";

@import "../../../../../../core/styles/settings/media-query";
@import "../../../../../../core/styles/settings/spacing";
@import "../../../../../../core/styles/settings/grid";
@import "../../../../../../core/styles/settings/colors";
@import "../../../../../../core/styles/settings/container-spacing";
@import "../../../../../../core/styles/mixins/button";
@import "../../../../../../core/styles/mixins/card";
@import "../../../../../../core/styles/mixins/elevation";
@import "../../../../../../core/styles/mixins/focus";
@import "../../../../../../core/styles/mixins/form";
@import "../../../../../../core/styles/mixins/grid";
@import "../../../../../../core/styles/mixins/link";
@import "../../../../../../core/styles/mixins/list";
@import "../../../../../../core/styles/mixins/palette";
@import "../../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../../core/styles/mixins/spacing";
@import "../../../../../../core/styles/mixins/visibility";
@import "../../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../../styles/settings/border-radius";
@import "../../../../../../styles/settings/card";
@import "../../../../../../styles/settings/depth";
@import "../../../../../../styles/settings/elevation";
@import "../../../../../../styles/settings/layout";
@import "../../../../../../styles/settings/palette";
@import "../../../../../../styles/settings/spacing";
@import "../../../../../../styles/settings/typography";

.date-range {
    &__add,
    &__range-picker {
        align-items: center;

        &:not(:first-child) {
            padding-top: 10px;
        }
    }

    &__delete {
        margin-left: 12px;
    }
}
