$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.priceLevelAlert {
    margin-bottom: 24px;
}

.form {
    // TODO temp ant overrides for this page only - disable funky ant-overrides in RadioButtonGroup.scss
    :global(.ant-radio-wrapper) {
        display: inline-flex;
        height: auto;
        line-height: inherit;
    }

    // TODO temp ant overrides for this page only - remove when switches replaced with redesigned components
    :global(.ant-switch) {
        background-color: var(--color-interactive-primary-disabled);

        &:active {
            background-color: var(--color-icon-primary-pressed);
        }

        &:disabled {
            background-color: var(--color-interactive-primary-disabled);
        }
    }

    :global(.ant-switch-checked) {
        background-color: var(--color-action-primary-default);

        &:active:not(:disabled) {
            background-color: var(--color-action-primary-pressed);
        }
    }
}

// TODO abstract common form container patterns into reusuable styles when more use cases / patterns emerge

.inlineFieldsContainer {
    composes: global(cx-3);
}

.tinyInlineField {
    width: 50px;
    display: inline-flex;
}

.selectRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        flex: 1 1 100%;
    }

    composes: global(cy-2);

    @include mq(md) {
        flex-wrap: nowrap;
        > * {
            margin-top: 0;
            flex: 1 1 0;
        }
    }

    :global(.ant-select) {
        width: 100%;
    }
}

.transferContainer {
    width: 100%;

    :global(.sortable-transfer) {
        padding-top: 0;
    }
}

.errorsGrid {
    @include grid(
        (
            "gridGutters": (
                xs: (
                    x: 16px,
                    y: 2px,
                ),
            ),
        )
    );
}

.columnJustifyEnd {
    justify-self: end;
}

.errorContainer {
    composes: errorContainer from "styles/shared/styles.scss";
}
