$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.base {
    @include buttonReset;
    composes: global(text-button);
    composes: global(cx-3);

    > svg {
        flex: none;
        width: 16px;
    }
}

.link {
    @include linkReset;
    composes: global(cx-3);

    > svg {
        display: inline-flex;
        width: 16px;
    }
}

.cursor-disabled {
    cursor: not-allowed;
}

.disabled {
    composes: cursor-disabled;
}

.shape-button {
    composes: base;
    border-radius: $border-radius;
}

.shape-pill {
    composes: base;
    border-radius: 9999px;
}

.shape-link {
    composes: link;
}

.shape-listItem {
    composes: base;
    composes: global(text-body);
    width: 100%;
    justify-content: flex-start;
}

.padding-regular-button,
.padding-regular-pill {
    padding-left: 16px;
    padding-right: 16px;
}

.padding-regular-link {
    padding-left: 0;
    padding-right: 0;
}

.padding-icon-button {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-iconUntilMedium-button,
.padding-iconUntilMedium-pill {
    padding-left: 10px;
    padding-right: 10px;

    @include mq(md) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.padding-icon-pill {
    padding: 10px;

    &.size-large-pill {
        padding: 11px;
    }
}

.padding-regular-listItem {
    padding-left: 10px;
    padding-right: 10px;
}

.size-regular-button {
    padding-top: 9px;
    padding-bottom: 9px;
}

.size-regular-pill {
    padding-bottom: 9px;
    padding-top: 9px;
}

.size-large-button {
    padding-top: 11px;
    padding-bottom: 11px;
}

.size-large-pill {
    &:not(.padding-icon-pill) {
        padding-top: 11px;
        padding-bottom: 11px;
    }
}

.size-regular-link {
    padding-top: 0;
    padding-bottom: 0;
}

.size-large-link {
    padding-top: 0;
    padding-bottom: 0;
    @include text("button");
}

.size-regular-listItem {
    padding-bottom: 6px;
    padding-top: 6px;
}

/* @private */
@mixin backgroundColor($color) {
    &:not(.noBackground) {
        background-color: $color;
    }
}

/* @private */
@mixin border($border) {
    &:not(.noBorder) {
        border: $border;
    }
}

/* @private */
@mixin buttonColors(
    $color,
    $bg,
    $border: 1px solid $bg,
    $hover-color: $color,
    $hover-bg: $bg,
    $hover-border: 1px solid $hover-bg,
    $active-color: $color,
    $active-bg: $bg,
    $active-border: 1px solid $active-bg,
    $disabled-color: $color,
    $disabled-bg: $bg,
    $disabled-border: 1px solid $disabled-bg,
    $loading-bg: $bg,
    $loading-border: 1px solid $loading-bg
) {
    color: $color;
    background-color: $bg;
    border: $border;
    outline-color: var(--color-border-highlight-default);

    @include safe-focus-removal {
        &:focus:not(:active):not(.disabled) {
            color: $hover-color;
            @include backgroundColor($hover-bg);
            @include border($hover-border);
        }
    }

    @media (hover: hover) {
        &:hover {
            color: $hover-color;
            @include backgroundColor($hover-bg);
            @include border($hover-border);
        }
    }

    &:active,
    &.active {
        color: $active-color;
        @include backgroundColor($active-bg);
        @include border($active-border);
    }

    &.loading {
        @include backgroundColor($loading-bg);
        @include border($loading-border);
    }

    &.disabled {
        color: $disabled-color;
        @include backgroundColor($disabled-bg);
        @include border($disabled-border);
    }
}

.color-default-primary-button,
.color-default-primary-pill {
    @include buttonColors(
        $color: var(--color-text-white),
        $bg: var(--color-action-primary-default),
        $hover-bg: var(--color-action-primary-hover),
        $active-bg: var(--color-action-primary-pressed),
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: var(--color-action-primary-disabled),
        $disabled-border: 1px solid var(--color-border-primary-disabled),
        $loading-bg: var(--color-action-primary-default--light),
        $loading-border: 1px solid var(--color-action-primary-default--light)
    );

    &.inGroup {
        &[aria-selected="false"] {
            @include buttonColors(
                $color: var(--color-text-default),
                $bg: var(--color-action-secondary-default),
                $border: 1px solid var(--color-border-primary-subtle),
                $hover-border: 1px solid var(--color-border-primary-subtle),
                $hover-color: var(--color-action-primary-hover),
                $active-color: var(--color-action-primary-pressed),
                $active-border: 1px solid var(--color-action-primary-pressed),
                $disabled-color: var(--color-text-disabled),
                $disabled-bg: var(--color-action-primary-disabled),
                $disabled-border: 1px solid var(--color-border-primary-disabled),
                $loading-bg: var(--color-action-secondary-default),
                $loading-border: 1px solid var(--color-border-primary-subtle)
            );
        }
    }
}

.color-critical-primary-button,
.color-critical-primary-pill {
    @include buttonColors(
        $color: var(--color-text-white),
        $bg: var(--color-action-critical-default),
        $border: 1px solid var(--color-action-critical-default),
        $hover-bg: var(--color-action-critical-hover),
        $hover-border: 1px solid var(--color-action-critical-hover),
        $active-bg: var(--color-action-critical-pressed),
        $active-border: 1px solid var(--color-action-critical-pressed),
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: var(--color-action-primary-disabled),
        $disabled-border: 1px solid var(--color-border-primary-disabled),
        $loading-bg: var(--color-action-critical-default--light),
        $loading-border: 1px solid var(--color-action-critical-default--light)
    );
}

.color-default-secondary-button,
.color-default-secondary-pill {
    @include buttonColors(
        $color: var(--color-text-default),
        $bg: var(--color-action-secondary-default),
        $border: 1px solid var(--color-border-primary-subtle),
        $hover-border: 1px solid var(--color-border-primary-subtle),
        $hover-color: var(--color-action-primary-hover),
        $active-color: var(--color-action-primary-pressed),
        $active-border: 1px solid var(--color-action-primary-pressed),
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: var(--color-action-primary-disabled),
        $disabled-border: 1px solid var(--color-border-primary-disabled),
        $loading-bg: var(--color-action-secondary-default),
        $loading-border: 1px solid var(--color-border-primary-subtle)
    );

    &.inGroup {
        &[aria-selected="true"] {
            @include buttonColors(
                $color: var(--color-action-primary-default),
                $bg: var(--color-action-secondary-default),
                $border: 1px solid var(--color-action-primary-default),
                $hover-border: 1px solid var(--color-action-primary-hover),
                $hover-color: var(--color-action-primary-hover),
                $active-color: var(--color-action-primary-pressed),
                $active-border: 1px solid var(--color-action-primary-pressed),
                $disabled-color: var(--color-text-disabled),
                $disabled-bg: var(--color-action-primary-disabled),
                $disabled-border: 1px solid var(--color-border-primary-disabled),
                $loading-bg: var(--color-action-secondary-default),
                $loading-border: 1px solid var(--color-border-primary-subtle)
            );
        }
    }
}

.color-critical-secondary-button,
.color-critical-secondary-pill {
    @include buttonColors(
        $color: var(--color-action-critical-default),
        $bg: var(--color-action-secondary-default),
        $border: 1px solid var(--color-action-critical-default),
        $hover-border: 1px solid var(--color-action-critical-hover),
        $hover-color: var(--color-action-critical-hover),
        $active-color: var(--color-action-critical-pressed),
        $active-border: 1px solid var(--color-action-critical-pressed),
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: var(--color-action-primary-disabled),
        $disabled-border: 1px solid var(--color-border-primary-disabled),
        $loading-bg: var(--color-action-secondary-default),
        $loading-border: 1px solid var(--color-action-critical-default)
    );
}

.color-default-primary-link {
    @include buttonColors(
        $color: var(--color-action-primary-default),
        $bg: transparent,
        $border: none,
        $hover-border: none,
        $hover-color: var(--color-action-primary-hover),
        $active-color: var(--color-action-primary-pressed),
        $active-border: none,
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: transparent,
        $disabled-border: none,
        $loading-bg: none,
        $loading-border: none
    );
}

.color-critical-primary-link {
    @include buttonColors(
        $color: var(--color-action-critical-default),
        $bg: transparent,
        $border: none,
        $hover-border: none,
        $hover-color: var(--color-action-critical-hover),
        $active-color: var(--color-action-critical-pressed),
        $active-border: none,
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: transparent,
        $disabled-border: none,
        $loading-bg: none,
        $loading-border: none
    );
}

.color-interactive-primary-link {
    @include buttonColors(
        $color: var(--color-interactive-primary-default),
        $bg: transparent,
        $border: none,
        $hover-border: none,
        $hover-color: var(--color-interactive-primary-hover),
        $active-color: var(--color-interactive-primary-pressed),
        $active-border: none,
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: transparent,
        $disabled-border: none,
        $loading-bg: none,
        $loading-border: none
    );
}

.color-default-primary-listItem {
    @include buttonColors(
        $color: var(--color-text-default),
        $bg: var(--color-surface-default),
        $hover-bg: var(--color-surface-background),
        $active-bg: var(--color-surface-background--dark),
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: var(--color-surface-default),
        $disabled-border: 1px solidvar(--color-surface-default),
        $loading-bg: var(--color-surface-default),
        $loading-border: 1px solidvar(--color-surface-default)
    );
}

.color-critical-primary-listItem {
    @include buttonColors(
        $color: var(--color-text-critical),
        $bg: var(--color-surface-default),
        $hover-bg: var(--color-surface-background),
        $active-bg: var(--color-surface-background--dark),
        $disabled-color: var(--color-text-disabled),
        $disabled-bg: var(--color-surface-default),
        $disabled-border: 1px solidvar(--color-surface-default),
        $loading-bg: var(--color-surface-default),
        $loading-border: 1px solidvar(--color-surface-default)
    );
}

.noBackground {
    background-color: transparent;
}

.noBorder {
    border: transparent;
}

.noWrap {
    white-space: nowrap;
}

.inGroup {
    &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(:last-of-type) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &[aria-selected="false"] {
            border-right: transparent !important;
        }
    }

    &[aria-selected="true"] + &[aria-selected="false"] {
        border-left: transparent !important;
    }
}
