$red-010: #feedec;
$red-020: #fddad8;
$red-030: #fbb5b1;
$red-040: #f57378;
$red-050: #ef465c;
$red-060: #cd3355;
$red-070: #ac234e;
$red-080: #8a1645;
$red-090: #720d40;
$red-100: #2d0709;

$blue-010: #edf5ff;
$blue-020: #d0e2ff;
$blue-030: #a6c8ff;
$blue-040: #78a9ff;
$blue-050: #4589ff;
$blue-060: #0f62fe;
$blue-070: #0043ce;
$blue-080: #002d9c;
$blue-090: #001d6c;
$blue-100: #001141;

$teal-010: #d9fbfb;
$teal-020: #9ef0f0;
$teal-030: #3ddbd9;
$teal-040: #08bdba;
$teal-050: #009d9a;
$teal-060: #007d79;
$teal-070: #005d5d;
$teal-080: #004144;
$teal-090: #022b30;
$teal-100: #081a1c;

$green-010: #defbe6;
$green-020: #a7f0ba;
$green-030: #6fdc8c;
$green-040: #42be65;
$green-050: #24a148;
$green-060: #198038;
$green-070: #0e6027;
$green-080: #044317;
$green-090: #022d0d;
$green-100: #071908;

$orange-010: #fff3eb;
$orange-020: #ffe7d6;
$orange-030: #ffc499;
$orange-040: #ffac70;
$orange-050: #ff832b;
$orange-060: #f56600;
$orange-070: #cc5500;
$orange-080: #a34400;
$orange-090: #662c00;
$orange-100: #291200;

$yellow-010: #fffaeb;
$yellow-020: #fff5d6;
$yellow-030: #ffebad;
$yellow-040: #ffdb70;
$yellow-050: #ffc400;
$yellow-060: #f5b800;
$yellow-070: #cc9900;
$yellow-080: #a37a00;
$yellow-090: #523d00;
$yellow-100: #140f00;

$purple-010: #f6f2ff;
$purple-020: #e8daff;
$purple-030: #d4bbff;
$purple-040: #be95ff;
$purple-050: #a56eff;
$purple-060: #8a3ffc;
$purple-070: #6929c4;
$purple-080: #491d8b;
$purple-090: #31135e;
$purple-100: #1c0f30;

$cool-gray-010: #f2f4f8;
$cool-gray-020: #dde1e6;
$cool-gray-030: #c1c7cd;
$cool-gray-040: #a2a9b0;
$cool-gray-050: #878d96;
$cool-gray-060: #697077;
$cool-gray-070: #4d5358;
$cool-gray-080: #343a3f;
$cool-gray-090: #21272a;
$cool-gray-100: #121619;

$gray-005: #fafafa;
$gray-010: #f4f4f4;
$gray-020: #e0e0e0;
$gray-030: #c6c6c6;
$gray-040: #a8a8a8;
$gray-050: #8d8d8d;
$gray-060: #6f6f6f;
$gray-070: #525252;
$gray-080: #393939;
$gray-090: #262626;
$gray-100: #161616;

$white: #ffffff;
$black: #000000;

$colors: (
    "red-010": $red-010,
    "red-020": $red-020,
    "red-030": $red-030,
    "red-040": $red-040,
    "red-050": $red-050,
    "red-060": $red-060,
    "red-070": $red-070,
    "red-080": $red-080,
    "red-090": $red-090,
    "red-100": $red-100,
    "blue-010": $blue-010,
    "blue-020": $blue-020,
    "blue-030": $blue-030,
    "blue-040": $blue-040,
    "blue-050": $blue-050,
    "blue-060": $blue-060,
    "blue-070": $blue-070,
    "blue-080": $blue-080,
    "blue-090": $blue-090,
    "blue-100": $blue-100,
    "teal-010": $teal-010,
    "teal-020": $teal-020,
    "teal-030": $teal-030,
    "teal-040": $teal-040,
    "teal-050": $teal-050,
    "teal-060": $teal-060,
    "teal-070": $teal-070,
    "teal-080": $teal-080,
    "teal-090": $teal-090,
    "teal-100": $teal-100,
    "green-010": $green-010,
    "green-020": $green-020,
    "green-030": $green-030,
    "green-040": $green-040,
    "green-050": $green-050,
    "green-060": $green-060,
    "green-070": $green-070,
    "green-080": $green-080,
    "green-090": $green-090,
    "green-100": $green-100,
    "orange-010": $orange-010,
    "orange-020": $orange-020,
    "orange-030": $orange-030,
    "orange-040": $orange-040,
    "orange-050": $orange-050,
    "orange-060": $orange-060,
    "orange-070": $orange-070,
    "orange-080": $orange-080,
    "orange-090": $orange-090,
    "orange-100": $orange-100,
    "yellow-010": $yellow-010,
    "yellow-020": $yellow-020,
    "yellow-030": $yellow-030,
    "yellow-040": $yellow-040,
    "yellow-050": $yellow-050,
    "yellow-060": $yellow-060,
    "yellow-070": $yellow-070,
    "yellow-080": $yellow-080,
    "yellow-090": $yellow-090,
    "yellow-100": $yellow-100,
    "purple-010": $purple-010,
    "purple-020": $purple-020,
    "purple-030": $purple-030,
    "purple-040": $purple-040,
    "purple-050": $purple-050,
    "purple-060": $purple-060,
    "purple-070": $purple-070,
    "purple-080": $purple-080,
    "purple-090": $purple-090,
    "purple-100": $purple-100,
    "cool-gray-010": $cool-gray-010,
    "cool-gray-020": $cool-gray-020,
    "cool-gray-030": $cool-gray-030,
    "cool-gray-040": $cool-gray-040,
    "cool-gray-050": $cool-gray-050,
    "cool-gray-060": $cool-gray-060,
    "cool-gray-070": $cool-gray-070,
    "cool-gray-080": $cool-gray-080,
    "cool-gray-090": $cool-gray-090,
    "cool-gray-100": $cool-gray-100,
    "gray-005": $gray-005,
    "gray-010": $gray-010,
    "gray-020": $gray-020,
    "gray-030": $gray-030,
    "gray-040": $gray-040,
    "gray-050": $gray-050,
    "gray-060": $gray-060,
    "gray-070": $gray-070,
    "gray-080": $gray-080,
    "gray-090": $gray-090,
    "gray-100": $gray-100,
    "white": $white,
    "black": $black,
);
