$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
}

.default {
    composes: container;
    composes: global(text-subheading);
    height: 22px;

    @include cx(2);
    @include px(3);
    @include py(1);
    border-radius: $border-radius-s;

    svg {
        width: 12px;
    }
}

.nowrap {
    white-space: nowrap;
}

.published {
    color: var(--color-text-success);
    background-color: var(--color-surface-success);
}

.staged {
    color: var(--color-blue-080);
    background-color: var(--color-blue-010);
}

.not-published {
    color: var(--color-text-subtle);
    background-color: var(--color-surface-neutral);
}

.warning {
    color: var(--color-text-warning);
    background-color: var(--color-surface-warning);
}
