$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    @include card("default");
    width: 100%;
}

.elevation-small {
    @include elevation("small");
}

.elevation-medium {
    @include elevation("medium");
}

.elevation-large {
    @include elevation("large");
}

.title {
    composes: global(text-reset);
    composes: global(text-heading);
}

.subtitle {
    composes: global(text-reset);
    composes: global(text-body);
}

.row {
    composes: global(p-6);
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: space-between;
}

.row--default {
    background-color: var(--color-surface-default);
}

.row--neutral {
    background-color: var(--color-surface-neutral);
}

.hidden {
    display: none;
}

.collapse--up {
    composes: row;
    padding-top: 0;
}

.collapse--down {
    composes: row;
    padding-bottom: 0;
}

.collapse--both {
    composes: row;
    padding-top: 0;
    padding-bottom: 0;
}

.border--top {
    margin-top: 1px;
    box-shadow: 0 -1px 0 0px var(--color-border-primary-subtle);
}

.border--bottom {
    margin-bottom: 1px;
    box-shadow: 0 1px 0 0px var(--color-border-primary-subtle);
}

.border--auto {
    & + & {
        margin-top: 1px;
        box-shadow: 0 -1px 0 0px var(--color-border-primary-subtle);
    }
}

.popup {
    composes: global(py-2);
    @include elevation("medium");
    min-width: 120px;
}
