$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.filter-dropdown-wrapper {
    padding: 4px 0;
    text-align: left;
    background-color: $color-grey-1;
    border-radius: $border-radius-normal;
    outline: none;
    width: 256px;
    filter: drop-shadow(-2px 0px 8px rgba(0, 0, 0, 0.15));

    li.ant-menu-item {
        margin: 0;
        margin-bottom: 0;
    }
    .ant-menu-item-group-title {
        text-transform: capitalize;
    }

    .ant-menu-item-group {
        .ant-menu-item-group-title {
            color: $color-grey-7;
            font-size: $font-body;
            padding: 0;
            margin: 0 10px;
            line-height: 20px;
        }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active,
    .ant-menu:not(.ant-menu-horizontal) li.ant-menu-item-selected {
        background: transparent;
        background-color: transparent;
    }

    label.ant-checkbox-wrapper {
        margin: 0px 8px;
        width: 100%;

        span:not(.ant-checkbox) {
            font-size: 14px;
            color: $color-grey-8;
        }
    }

    li.ant-menu-item-group ul.ant-menu-item-group-list {
        padding: 0 20px;

        .ant-menu-item {
            margin: 12px 0;
            padding: 0;
            line-height: 22px;
            height: 22px;
        }
    }
    .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
        max-height: 256px;
        padding: 8px 0px;
        overflow-y: auto;
        margin: 0;
    }

    div.filter-commands {
        text-align: right;
        position: sticky;
        display: flex;
        justify-content: flex-end;
        border-top: solid 1px $color-grey-4;
        z-index: 5;
        padding: 10px 16px;
        background: $color-grey-1;

        .ant-btn {
            margin-right: 0;
        }
    }
}
