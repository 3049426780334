$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

.weekdays-container {
    text-align: right;

    span {
        color: #cccccc;
        background: white;
        width: 24px;
        height: 24px;
        line-height: 22px;
        border-radius: 50%;
        text-align: center;
        display: inline-block;
        margin-left: 5px;
        font-size: 10px;
        border: 1px solid white;
    }

    .day {
        &__is-active {
            border-color: #ef465c;
            color: #ef465c;

            &.day__is-disabled {
                border-color: #e8959e;
                color: #e8959e;
            }
        }

        &__is-current {
            color: #000000;

            &.day__is-active:not(.day__is-disabled) {
                background: #ef465c;
                color: white;
            }
        }
    }
}
