$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.checkboxContainer {
    display: flex;
    align-items: center;

    &:hover {
        input[type="checkbox"] {
            border-color: var(--color-action-primary-hover);
        }
    }

    input[type="checkbox"] {
        position: absolute;
        height: 16px;
        width: 16px;
        opacity: 0;
        margin: 0;
        padding: 0;

        &[disabled] + span {
            color: var(--color-text-disabled);
            border-color: var(--color-border-primary-disabled);

            &::before {
                border-color: var(--color-border-primary-disabled);
                background: var(--color-surface-disabled);
            }

            &:hover {
                &::before {
                    border-color: var(--color-border-primary-disabled);
                }
            }
        }

        &:checked ~ .checkboxIcon {
            transform: scale(1);
        }

        &:checked + span {
            &::before {
                border-color: var(--color-action-primary-default);
                background: var(--color-action-primary-default);
            }
        }

        &:disabled:checked ~ .checkboxIcon {
            svg {
                * {
                    fill: var(--color-icon-primary-disabled);
                }
            }
        }

        &:disabled:checked + span {
            &::before {
                background: var(--color-surface-disabled);
                border-color: var(--color-border-primary-disabled);
            }
        }

        &:focus + span {
            &::before {
                @include focus-outline();
            }
        }

        &:indeterminate + span {
            &:hover,
            &:focus {
                &::after {
                    border-color: var(--color-action-primary-hover);
                    background-color: var(--color-action-primary-hover);
                }
            }

            &::after {
                content: "";
                width: 8px;
                height: 8px;
                left: 4px;
                background: var(--color-action-primary-default);
                border: var(--color-action-primary-default);
                border-radius: 0;
            }
        }

        &:disabled:indeterminate + span {
            &::after {
                background: var(--color-icon-primary-disabled);
                border-color: var(--color-border-primary-disabled);
            }
        }
    }
}

.label {
    position: relative;
    cursor: pointer;

    &:hover,
    &:focus {
        .labelText {
            &::before {
                border-color: var(--color-action-primary-hover);
            }
        }
    }

    &:focus {
        @include focus-outline(false);
    }
}

.label.disabled {
    cursor: initial;

    &:hover,
    &:focus {
        .labelText {
            &::before {
                border-color: var(--color-border-primary-disabled);
            }
        }
    }
}

.labelText {
    composes: global(pr-3);
    composes: global(text-body);
    color: var(--color-text-default);
    user-select: none;

    position: relative;
    transform: translate3d(0, 0, 0);
    min-height: 16px;
    display: flex;
    align-items: center;
    color: inherit;
    margin-left: 16px + 8px;

    &::before,
    &::after {
        position: absolute;
        height: 16px;
        width: 16px;
        left: 0;
        top: 50%;
        border: 1px solid rgba(0, 0, 0, 0.25);
        background: var(--color-surface-default);
        border-radius: 2px;
        transition: background-color 100ms ease-in;
        box-sizing: border-box;
        transform: translate3d(-24px, -50%, 0);
    }

    &::before {
        content: "";
    }
}

.checkboxIcon {
    position: absolute;
    top: calc(50% - 8px);
    transform: scale(0);
    transition: 120ms transform ease-in-out;

    svg {
        * {
            fill: var(--color-surface-default);
        }
    }
}

// quick fixes to make checkbox look ok when label empty
// TODO redo checkbox styling and remove
.labelTextHidden {
    composes: labelText;
    padding-right: 0;
    width: 16px;
    margin-right: 0;
    margin-left: 0;

    &::before,
    &::after {
        transform: translate3d(0, -50%, 0);
    }
}
