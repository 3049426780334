.hide-until-medium {
    @include mq($until: md) {
        display: none;
    }
}

.hide-until-large {
    @include mq($until: lg) {
        display: none;
    }
}

.hide-on-medium {
    @include mq(md) {
        display: none;
    }
}

.hide-on-large {
    @include mq(lg) {
        display: none;
    }
}

.hide-if-parent-location {
    .is-parent-location & {
        display: none;
    }
}

.hide-if-child-location {
    .is-child-location & {
        display: none;
    }
}

.sr-only {
    @include screenreaders-only;
}

.sr-only-until-medium {
    @include mq($until: md) {
        @include screenreaders-only;
    }
}
