$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.confirm-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: scale-up 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow-y: hidden;

    &-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 36px 0;
        z-index: 2000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        animation: fade-in 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
        background: rgba(0, 0, 0, 0.8);

        &.ReactModal__Overlay--before-close {
            animation-name: fade-out;
            animation-fill-mode: forwards;
        }
    }

    &--before-close {
        animation-name: scale-down;
        animation-fill-mode: forwards;
    }

    &__body {
        background: $color-grey-1;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: $border-radius-normal;
        padding: 32px;
        margin: 0 60px;
        width: 600px;
        overflow-y: auto;
    }
    &__header {
        display: flex;
        .anticon {
            margin-right: 16px;
            svg * {
                fill: $color-gold-3;
            }
        }
        .title {
            font-size: $font-subtitle;
            color: $color-grey-9;
            font-weight: $font-weight-semi-bold;
            display: block;
        }
        .description {
            font-size: $font-header;
        }
    }

    &__content {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;

        &__cancel {
            margin-right: 18px;
        }
    }
}

@media screen and (max-width: 769px) {
    .confirm-modal__body {
        width: 85%;
    }
}
