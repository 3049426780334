$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(px-6);
    composes: global(py-4);
}

.containerItem {
    composes: container;
    & + & {
        border-top: 1px solid var(--color-border-primary-subtle);
    }
}

.containerPrimary {
    composes: containerItem;
    background-color: var(--color-surface-default);
}

.containerSecondary {
    composes: containerItem;
    background-color: var(--color-surface-subtle);
}

.containerHeader {
    composes: container;
    background-color: var(--color-surface-default);
}

.containerHeaderHidden {
    composes: container;
    padding: 0;
    height: 0;
    overflow: hidden;
}

.containerHeaderTop {
    composes: containerHeader;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
}

.containerHeaderBottom {
    composes: containerHeader;
    border-bottom: 1px solid var(--color-border-primary-subtle);
}

.innerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
}

.innerContainerHeaderTop {
    composes: innerContainer;
    composes: global(cx-4);
    justify-content: flex-end;
}

.cellGrow {
    flex: 1 1 auto;
}

.cellStatic {
    flex: none;
}

.check {
    flex: none;
    display: flex;
    min-height: 40px;
    align-items: center;
    justify-content: center;

    @include mq($until: md) {
        align-self: flex-start;
    }
}

.controlsContainer {
    composes: global(cx-5);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    z-index: 1;
}

.controlsInnerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
}

.controlsLarge {
    @include mq($until: md) {
        display: none;
    }
}

.controlsCompact {
    flex: none;
    margin-left: auto;
    @include mq(md) {
        display: none;
    }
}

.contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    color: var(--color-text-default);
    overflow: hidden;
    composes: global(cx-6);

    @include mq($until: md) {
        flex-wrap: wrap;
    }
}

.check + .controlsContainer,
.check + .contentContainer {
    @include ml(5);
}

.cell {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cellHeader {
    composes: global(text-subheading);
}

.cellName {
    composes: global(text-subheading);
    composes: cell;
    flex: 1 1 auto;

    @include mq($until: md) {
        min-width: 100%;
    }
}

.cellSmall {
    composes: cell;
    width: 120px;
    flex: none;

    @include mq($until: md) {
        width: auto;
        max-width: 120px;
        color: var(--color-text-subtle);
    }
}

.cellNumber {
    text-align: right;
}

.cellHideFirst {
    @include mq($until: 1200px) {
        display: none;
    }
}

.cellHideLast {
    @include mq($until: xl) {
        display: none;
    }
}

.cellFirstWrap {
    @include mq($until: md) {
        margin-left: 0;
    }
}

.cellLabel {
    composes: global(hide-on-medium);
    color: var(--color-text-subtle);
}

.headerLabel {
    @include text(subheading);
    color: var(--color-text-default);
}

.badge {
    @include my(-1);
    font-size: 12px;
}

.displayNamePrefix {
    user-select: none;
}
