$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.content--container,
.ant-drawer,
.ant-modal {
    .imageupload--preview {
        background: #f2f2f2;

        img, video {
            width: 100%;
            max-height: 630px;
        }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: var(--theme-primary-color);
    }

    .imageupload--label {
        display: flex;
        justify-content: center;
        padding: 25px;
        font-size: 20px;
        background: #f2f2f2;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .imageupload--container {
        .ant-upload.ant-upload-drag .ant-upload,
        .ant-upload-drag-container {
            padding: 0;
            height: 630px;
        }

        .imageupload--input {
            padding: 16px 0;
        }
        .ant-upload-drag-icon {
            .anticon {
                color: #ababab !important;
            }
        }
        .ant-upload-text {
            font-size: 12px !important;
        }
    }
}
