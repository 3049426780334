@mixin safe-focus-removal {
    :global(body:not(.safe-focus-removal)) & {
        @content;
    }
}

@mixin focus-outline($safe-removal: true) {
    $focus-style: 0 0 0 2px var(--color-border-highlight-default);

    @if $safe-removal {
        @include safe-focus-removal {
            box-shadow: $focus-style;
        }
    } @else {
        box-shadow: $focus-style;
    }
}

@mixin focus-border($safe-removal: true) {
    $focus-style: 2px solid var(--color-border-highlight-default);

    @if $safe-removal {
        @include safe-focus-removal {
            border: $focus-style;
        }
    } @else {
        border: $focus-style;
    }
}

@mixin focus-border-pseudo($psuedo-element: before) {
    &:focus:not(:active):not(.disabled) {
        &::#{$psuedo-element} {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            @include focus-border(false);
        }
    }
}

@mixin focus-border-element($safe-removal: true, $psuedo-element: before) {
    @if $safe-removal {
        @include safe-focus-removal {
            @include focus-outline-pseudo($psuedo-element);
        }
    } @else {
        @include focus-outline-pseudo($psuedo-element);
    }
}
