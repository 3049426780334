/* Colours */
$color-business: #7b8daf; /* me&u color */
$color-black: #000000; /* Black */
$color-grey-1: #ffffff; /* Grey / grey-1 */
$color-grey-2: #fafafa; /* Grey / grey-2 */
$color-grey-3: #f5f5f5; /* Grey / grey-3 */
$color-grey-4: #e8e8e8; /* Grey / grey-4 */
$color-grey-5: #d9d9d9; /* Grey / grey-5 */
$color-grey-6: #bfbfbf; /* Grey / grey-6 */
$color-grey-7: #8c8c8c; /* Grey / grey-7 */
$color-grey-8: #595959; /* Grey / grey-8 */
$color-grey-9: #262626; /* Grey / grey-9 */
$color-grey-10: #9ba9ab; /* Grey / grey-10 */

$color-blue-1: #597ef7; /* Geek Blue / geekblue-5 */
$color-blue-2: #85a5ff; /* Geek Blue / geekblue-4 */
$color-blue-3: #1890ff; /* Daybreak Blue / blue-6 */

$color-cool-grey-10: #909eb9; /* Cool Grey 10 / cool-grey-10 */
$color-cool-grey-60: #697077; /* Cool Grey 20 / cool-grey-20 */
$color-cool-grey-90: #21272a; /* Cool Grey 90 / cool-grey-90 */

$color-red: #ff4d4f; /* Dust Red / red-5 */
$color-red-2: #f5222d; /* Dust Red / red-6 */
$color-red-3: #fff1f0; /* Dust Red / red-1 */
$color-green: #52c41a; /* Polar Green / green-6 */
$color-orange: #fa8c16; /* Sunset Orange / orange-6 */
$color-gold-1: #fffbe6; /* Calendula Gold / gold-1 */
$color-gold-2: #ad6800; /* Calendula Gold / gold-8 */
$color-gold-3: #faad14; /* Calendula Gold / gold-6 */

/* Font-sizes */
$font-tag: 8px;
$font-body: 12px;
$font-header: 14px;
$font-subtitle: 16px;
$font-title: 18px;

/* Line-heights */
$line-height-default: 183%;
$line-height-tag: 13px;
$line-height-body: 18px;
$line-height-header: 22px;
$line-height-subtitle: 20px;
$line-height-title: 22px;

/* Font-weight */

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/* Spacing */
$spacing-xs: 4px;
$spacing-small: 8px;
$spacing-component: 12px;
$spacing-title: 18px;
$double-spacing-component: 24px;
$spacing-big-header: 32px;
$horizontal-content-spacing: 8.3vw;
$top-content-spacing: 100px;

/* Border-Radius */
$border-radius-small: 2px;
$border-radius-normal: 4px;
$border-radius-large: 8px;
