$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.group-tabs-page {
    &__intro {
        background-color: $color-grey-2;
        padding: 24px;
        border-radius: 2px;
        font-size: 16px;
        line-height: 24px;
        color: $color-grey-8;
        margin-top: 16px;

        &__header {
            font-weight: $font-weight-semi-bold;
            font-size: 24px;
            line-height: 32px;
            color: $color-grey-9;
        }

        &__title {
            font-weight: $font-weight-semi-bold;
            margin-top: 16px;
        }

        &__subtitle {
            font-size: 14px;
            line-height: 22px;
            color: $color-grey-7;
        }
    }
}
