$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.form,
.tabsContainer,
.tabPanel,
.container,
.errorContainer,
.resultsTable {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.container {
    composes: global(cy-5);
}

.alertContainer {
    composes: global(mb-6);
}

.errorContainer {
    padding-top: 64px;
}

.resultsTable {
    // full width on small screens
    @include mq($until: md) {
        @include mx(-7);
    }
}

.tabPanel {
    background-color: var(--color-surface-default);
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    @include elevation;
}

.exportButton {
    composes: global(button-reset);
}

.exportMenuContainer {
    border-radius: var(--border-radius);
    background-color: var(--color-surface-default);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.list {
    composes: list from "styles/shared/styles.scss";
}

.listItem {
    width: 144px;
    composes: listItem from "styles/shared/styles.scss";
    color: var(--color-text-default);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    min-width: 100%;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    @media (hover: hover) {
        &:hover {
            &:before {
                content: "";
                background-color: var(--color-surface-background);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }
        }
    }
}

.emptyMessageContainer {
    background-color: var(--color-surface-default);
}
