@mixin inputReset() {
    border: none;
    color: inherit;
    font: inherit;
    background-color: transparent;
    padding: 0;

    // remove autofill background
    // @see https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 0s 999999s;
    }
}
