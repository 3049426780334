// note these breakpoint keys must match those in $mq-breakpoints
// default grid config: override in part or whole when calling grid mixins
$defaultGridConfig: (
    "gridGutters": (
        xs: (
            x: 16px,
            y: 16px,
        ),
    ),
    "gridPadding": (
        xs: 16px,
    ),
    "colCount": 12,
    "maxWidth": 1156px,
);

$grid-breakpoints: (xs, md, xl);
