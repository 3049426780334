$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    height: 100%;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include mq(sm) {
        width: 100%;
        max-width: 360px;
    }
}

.list {
    composes: list from "styles/shared/styles.scss";
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

.listUnloaded {
    min-height: 100px;
    overflow: hidden;
}

.listItem {
    composes: listItem from "styles/shared/styles.scss";
}

.listItemMessage {
    composes: listItem;
    color: var(--color-text-disabled);
}

.label {
    overflow: hidden;
    display: block;
}

.filterCreate {
    composes: global(p-4);
    composes: global(cx-4);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--color-border-menu-default);
}

.search {
    flex: 1 1 auto;
}

.create {
    flex: none;
}

.listItemSelected {
    composes: listItemSelected from "styles/shared/styles.scss";
}

.hover {
    @media (hover: hover) {
        &:hover {
            color: inherit;
            &:before {
                content: "";
                background-color: var(--color-surface-background);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }
        }
    }
}

.createButton {
    composes: hover;
}

@mixin highlightBackground {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-surface-background);
    z-index: -1;
}

.link {
    display: block;
    color: var(--color-text-default);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    min-width: 100%;

    @include safe-focus-removal {
        &:focus:not(:active):not(.disabled) {
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid var(--color-border-highlight-default);
                border-radius: $border-radius;
                background: transparent;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            color: var(--color-text-black);
            &::before {
                @include highlightBackground;
            }
        }
    }
}

.linkSelected {
    font-weight: 600;
    color: var(--color-text-default);

    &::before {
        @include highlightBackground;
    }
}

.linkActiveLocation {
    font-weight: 600;
    color: var(--color-interactive-primary-default);
}

.groupText {
    color: var(--color-text-subtle);
    text-transform: uppercase;
}

.groupTitle {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.parentText {
    color: var(--color-text-default);
    font-weight: 500;
}

.itemText {
    color: var(--color-text-default);
}

.groupLabel {
    composes: label;
    composes: groupText;
}

.parentLabel {
    composes: label;
    composes: parentText;
}

.itemLabel {
    composes: label;
    composes: itemText;
}
