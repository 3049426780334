$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.container {
    composes: global(cy-1);
    color: var(--color-text-default);
    width: 100%;
}

// marker class for descendants dont delete
.containerError {
    composes: container;
}

.inputContainer {
    composes: global(px-4);
    composes: global(text-input);
    padding-top: calc(var(--spacing-3) - 1px);
    padding-bottom: calc(var(--spacing-3) - 1px);
    border-radius: var(--border-radius);
    background-color: var(--color-surface-default);
    border: 1px solid var(--color-border-primary-subtle);
    display: flex;
    width: 100%;
}

.inputContainerFocused {
    composes: inputContainer;
    @include focus-outline(false);
}

.inputContainerDisabled {
    composes: inputContainer;
    background-color: var(--color-surface-disabled);
    border: 1px solid var(--color-border-primary-disabled);
    color: var(--color-text-disabled);
}

.inputContainerError {
    composes: inputContainer;
    background-color: var(--color-surface-critical);
    border: 1px solid var(--color-border-critical-default);
    color: var(--color-text-critical);
}

.beforeContainer {
    composes: global(pr-3);
    flex: none;
    display: flex;
    align-items: center;
}

.input {
    composes: global(input-reset);
    outline: none;
    flex: 1 1 auto;
    min-width: 0;
    width: 100%;

    ::placeholder {
        color: var(--color-text-subtle);
    }

    .containerError &::placeholder {
        color: var(--color-text-critical);
    }

    &:disabled::placeholder {
        color: var(--color-text-disabled);
    }

    &:read-only::placeholder {
        color: var(--color-text-disabled);
    }

    &:disabled {
        color: inherit;
        -webkit-text-fill-color: var(--color-text-disabled); // fixes safari wierdness
    }
}

.afterContainer {
    composes: global(pl-3);
    flex: none;
    display: flex;
    align-items: center;
}

.clearButton {
    display: inline-flex;
    composes: global(button-reset);
    width: 16px;
    height: 16px;
}

.clearIcon {
    color: var(--color-icon-primary-subtle);

    @at-root.containererror & {
        color: rgba(var(--color-text-critical--rgb), 0.5);
    }
}

.inputIcon {
    color: var(--color-icon-primary-default);

    .inputContainerError & {
        color: var(--color-text-critical);
    }

    .inputContainerDisabled & {
        color: var(--color-icon-primary-disabled);
    }
}
