$env: "production";

@import "../../../../../core/styles/settings/media-query";
@import "../../../../../core/styles/settings/spacing";
@import "../../../../../core/styles/settings/grid";
@import "../../../../../core/styles/settings/colors";
@import "../../../../../core/styles/settings/container-spacing";
@import "../../../../../core/styles/mixins/button";
@import "../../../../../core/styles/mixins/card";
@import "../../../../../core/styles/mixins/elevation";
@import "../../../../../core/styles/mixins/focus";
@import "../../../../../core/styles/mixins/form";
@import "../../../../../core/styles/mixins/grid";
@import "../../../../../core/styles/mixins/link";
@import "../../../../../core/styles/mixins/list";
@import "../../../../../core/styles/mixins/palette";
@import "../../../../../core/styles/mixins/settings-to-props";
@import "../../../../../core/styles/mixins/spacing";
@import "../../../../../core/styles/mixins/visibility";
@import "../../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../../styles/settings/border-radius";
@import "../../../../../styles/settings/card";
@import "../../../../../styles/settings/depth";
@import "../../../../../styles/settings/elevation";
@import "../../../../../styles/settings/layout";
@import "../../../../../styles/settings/palette";
@import "../../../../../styles/settings/spacing";
@import "../../../../../styles/settings/typography";

@import "src/common/scaffolding/components/variables";

.data-listing-page {
    .list-container {
        margin-top: 20px;
        border: none !important;

        &.ant-list-bordered {
            .list-item {
                border-bottom: 1px solid #f0f0f0;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .list-item {
            background: white;
            margin-bottom: 10px;
            border: 1px solid #f0f0f0;
            border-radius: 4px;
            user-select: none;

            &:hover {
                cursor: pointer;
                background: var(--theme-soft-color);
            }

            &.list-item__active {
                background: var(--theme-soft-color);

                &:not(.anticon) {
                    color: #6b7c98;
                }
            }

            &.has-image {
                padding: 8px 16px 8px 8px;
                min-height: 52px;

                .item-wrapper__display-name {
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                }
            }

            a {
                color: #8b8b8b;
            }

            .ant-list-item-content {
                overflow: hidden;
            }

            .item-wrapper {
                width: 100%;
                flex-wrap: nowrap;
                align-items: center;
                display: flex;
                align-items: center;

                &__display-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    user-select: none;
                }

                &-outer {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }

            .ant-form-item {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .ant-list-item-action {
                margin-left: 16px;
                > li {
                    padding: 0;
                }
            }
        }
    }
}
