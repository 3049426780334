$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.root {
    display: flex;
}

.rootColumn {
    composes: root;
    flex-direction: column;
    composes: global(cy-6);
}

.container {
    display: inline;
}

.containerColumn {
    composes: global(cy-2);
}

.inputContainer {
    display: flex;
    align-items: center;
    composes: global(mr-3);

    &:hover,
    &:focus {
        input[type="radio"] {
            border-color: var(--color-action-primary-hover);
        }
    }

    &.disabled {
        input[type="radio"],
        .label {
            cursor: default;
        }
    }

    input[type="radio"] {
        margin: 0;
        appearance: none;
        background-color: $white;
        font: inherit;
        color: currentColor;
        width: 16px;
        height: 16px;
        border: 1px solid var(--color-border-primary-subtle);
        border-radius: 50%;
        cursor: pointer;
        display: grid;
        place-content: center;

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            background-color: var(--color-action-primary-default);
        }

        &:checked {
            border-color: var(--color-action-primary-default);

            &::before {
                transform: scale(1);
            }
        }

        &[disabled] {
            border-color: var(--color-border-primary-disabled);
            &::before {
                box-shadow: inset 1em 1em var(--color-border-primary-disabled);
            }
        }

        &:focus {
            outline: none;
            @include focus-outline;
        }
    }
}

.disabled {
    .label {
        color: var(--color-text-disabled);
    }
}

.label {
    composes: global(pl-3);
    composes: global(pr-3);
    composes: global(text-body);
    color: var(--color-text-default);
    cursor: pointer;
    user-select: none;
}

.description {
    color: var(--color-text-disabled);
    composes: global(pl-7);
    line-height: 22px;
}

.legend {
    composes: global(text-body);
    composes: global(mb-2);
    color: var(--color-text-default);
}
