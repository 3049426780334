$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.mobile-wrapper {
    padding: 25px 10px;
    border-radius: 30px;
    border: 1px solid #e0e0e0;
    color: #23262a;
    background: currentColor;
    width: 300px;
    float: left;
    box-shadow: inset 0px 0px 0px -4px rgba(255, 255, 255, 0.1), 1px 1px 6px rgba(0, 0, 0, 0.05),
        1px 1px 8px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    max-width: 80%;
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;

    .notch {
        position: absolute;
        width: 55%;
        height: 3.5%;
        z-index: 4;
        background: currentColor;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 3px;

        &:before,
        &:after {
            content: "";
            height: 8px;
            position: absolute;
            top: 0;
            width: 8px;
        }

        &:after {
            background: radial-gradient(
                circle at bottom left,
                transparent 0,
                transparent 70%,
                currentColor 70%,
                currentColor 100%
            );
            left: -7px;
        }

        &:before {
            background: radial-gradient(
                circle at bottom right,
                transparent 0,
                transparent 70%,
                currentColor 70%,
                currentColor 100%
            );
            right: -7px;
        }

        .camera {
            background: #ababab;
            opacity: 0.2;
            margin-left: 5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
        }

        .speaker {
            width: 50%;
            height: 20%;
            background: #ababab;
            opacity: 0.2;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background-color: white;
        overflow: hidden;
    }
}
.branding-preview {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
    color: inherit;

    &__modes {
        margin-top: 20px;

        .ant-btn {
            border-radius: 0;
            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &__menu_background,
    &__background {
        svg,
        path {
            max-height: 100px;
            max-width: 175px;
            height: 100%;
        }
        svg:not(.multi-color) {
            &:not([fill="none"]) {
                &:not([stroke]),
                &[stroke="none"] {
                    fill: white;
                }
            }

            *[fill]:not([fill="none"]):not(.ignore-svg-color) {
                fill: white !important;
            }

            *[stroke]:not([stroke="none"]):not(.ignore-svg-color) {
                stroke: white !important;
            }
        }
    }

    &__background {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: bottom;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        padding-top: calc(3.5% + 20px);
    }

    &__menu_background {
        position: relative;
        &::before {
            content: "";
            background-repeat: no-repeat;
            background-size: cover;
            background-image: var(--menu-background-image);
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }

    &__menu {
        max-width: 100%;
        max-height: calc(33% + 3.5%);
        width: 100%;
        height: 100%;
        background-size: cover !important;
        background-position: center !important;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        padding-top: calc(3.5%);
        color: white;
        flex-direction: column;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);

        svg,
        path {
            max-height: 40%;
        }
        svg:not(.multi-color) {
            &:not([fill="none"]) {
                &:not([stroke]),
                &[stroke="none"] {
                    fill: white;
                }
            }

            *[fill]:not([fill="none"]):not(.ignore-svg-color) {
                fill: white !important;
            }

            *[stroke]:not([stroke="none"]):not(.ignore-svg-color) {
                stroke: white !important;
            }
        }

        &__title {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;

            svg {
                max-height: 100%;
                height: 100%;
                padding: 4px;
            }
            svg:not(.multi-color) {
                &:not([fill="none"]) {
                    &:not([stroke]),
                    &[stroke="none"] {
                        fill: black;
                    }
                }

                *[fill]:not([fill="none"]):not(.ignore-svg-color) {
                    fill: black !important;
                }

                *[stroke]:not([stroke="none"]):not(.ignore-svg-color) {
                    stroke: black !important;
                }
            }
        }

        &__category {
            padding: 14px 18px;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;

            &__title {
                font-size: 21px;
                font-weight: 800;
            }
            &__row {
                display: flex;
                justify-content: space-between;

                span {
                    max-width: 48%;
                    width: 100%;
                    margin-top: 10px;
                }
                div {
                    border-radius: 4px;
                    max-width: 100%;
                    height: auto;
                    min-height: 80px;
                    max-height: 100px;
                    display: flex;
                    object-fit: cover;
                    border: 1px solid #b3b3b3;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__list {
        margin-top: 22px;

        &__item {
            height: 100px;
            font-size: 18px;

            &__image {
                height: 80px;
                max-width: 100%;
                width: 100%;
                background-size: cover !important;
                background-position: center !important;
                position: relative;

                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
            }
        }
    }

    .loading {
        position: absolute;
        top: 0;
        bottom: 30px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #808080;
        text-align: center;

        span {
            margin-top: 20px;
        }

        svg,
        path {
            font-size: 50px;
            fill: #808080;
            max-height: 100%;
        }
    }

    .empty {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #808080;
        text-align: center;
        background-color: #fbfbfb;

        &:before {
            content: "";
            position: absolute;
            top: -25%;
            bottom: -25%;
            left: 50%;
            border-style: solid;
            transform: rotate(-26deg);
            border-width: 0.5px;
            border-color: transparent #b3b3b3 transparent transparent;
            z-index: 0;
            opacity: 0.5;
        }

        &:after {
            content: "";
            position: absolute;
            top: -25%;
            bottom: -25%;
            left: 50%;
            border-style: solid;
            transform: rotate(26deg);
            border-width: 0.5px;
            border-color: transparent #b3b3b3 transparent transparent;
            z-index: 0;
            opacity: 0.5;
            .small & {
                top: -45%;
                bottom: -45%;
                transform: rotate(55deg);
            }
        }

        &.small {
            &:before {
                top: -50%;
                bottom: -50%;
                transform: rotate(-53.5deg);
            }

            &:after {
                top: -50%;
                bottom: -50%;
                transform: rotate(53.5deg);
            }
        }

        &.smaller {
            &:before {
                top: -140%;
                bottom: -140%;
                transform: rotate(-74.5deg);
            }

            &:after {
                top: -140%;
                bottom: -140%;
                transform: rotate(74.5deg);
            }
        }

        &.tile {
            height: auto;
            &:before {
                top: -38%;
                bottom: -38%;
                transform: rotate(-56deg);
                left: 50%;
            }
            &:after {
                top: -38%;
                bottom: -38%;
                transform: rotate(56deg);
            }
        }
    }
}
