// global z-index registry
// Ant message = 1010
// Ant drawer = 1000
$z-chatbot: 2000;
$z-tooltip: 1500;
$z-modal: 1200;
$z-message: 1100;
$z-popup: 1000;
$z-header: 900;
$z-nav: 800;
$z-edit-modal: 700;
$z-sticky-header: 600;
