$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.root {
    z-index: $z-modal;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.container {
    background-color: rgba(var(--color-surface-darken--rgb), 0.45);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 60px;

    @include mq(md) {
        justify-content: center;
        @include px(7);
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.container--top {
    composes: container;
    justify-content: flex-start;

    @include mq(md) {
        justify-content: flex-start;
    }
}

.container--bottom {
    composes: container;
    justify-content: flex-end;

    @include mq(md) {
        justify-content: flex-end;
    }
}

.cardContainer {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cardContainer--narrow {
    composes: cardContainer;
    @include mq(md) {
        max-width: 520px;
    }
}

.cardContainer--standard {
    composes: cardContainer;
    @include mq(md) {
        max-width: 707px;
    }
}

.cardContainer--wide {
    composes: cardContainer;
    @include mq(md) {
        max-width: 1200px;
    }
}

.card {
    overflow: hidden;
}
