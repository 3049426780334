$env: "production";

@import "../../../styles/settings/media-query";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/grid";
@import "../../../styles/settings/colors";
@import "../../../styles/settings/container-spacing";
@import "../../../styles/mixins/button";
@import "../../../styles/mixins/card";
@import "../../../styles/mixins/elevation";
@import "../../../styles/mixins/focus";
@import "../../../styles/mixins/form";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/link";
@import "../../../styles/mixins/list";
@import "../../../styles/mixins/palette";
@import "../../../styles/mixins/settings-to-props";
@import "../../../styles/mixins/spacing";
@import "../../../styles/mixins/visibility";
@import "../../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.root {
    // make ant control look consistent with me&u Input/Select styling
    &:global(.ant-picker){
        @include px(4);
        @include text(text-input);
        padding-top: calc(var(--spacing-3) - 1px);
        padding-bottom: calc(var(--spacing-3) - 1px);
        border-radius: var(--border-radius);
        background-color: var(--color-surface-default);
        border: 1px solid var(--color-border-primary-subtle);

        &:global(.ant-picker-focused){
            @include focus-outline(false);
        }

        input {
            color: inherit;
        }
    }

    &:global(.ant-picker-disabled){
        background-color: var(--color-surface-disabled);
        color: var(--color-text-disabled);
        border: 1px solid var(--color-border-primary-disabled);
    }
}

.rootError {
    composes: root;

    &:global(.ant-picker){
        background-color: var(--color-surface-critical);
        border: 1px solid var(--color-border-critical-default);
        color: var(--color-text-critical);
    }
}

.popup {

    border-radius: var(--border-radius);
    
    :global {
        .ant-picker-panel-container {
            @include elevation;
        }

        .ant-picker-time-panel-column {
            &:not(:first-child) {
                border-left: 1px solid var(--color-border-primary-subtle);
            }
        }

        .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
            background: rgba(var(--color-action-secondary-hover--rgb), 0.5);
            color: var(--color-interactive-primary-default);
            font-weight: 500;
        }

        .ant-picker-panel {
            .ant-picker-footer {

                border-top: 1px solid var(--color-border-primary-subtle);

                .ant-btn.ant-btn-primary {
                    &:not([disabled]){
                        color: var(--color-text-white);
                        background-color: var(--color-action-primary-default);
                        border-color: var(--color-action-primary-default);

                        @media (hover: hover) {
                            &:hover {
                                background-color: var(--color-action-primary-hover);
                                border-color: var(--color-action-primary-hover);
                            }
                        }

                        &:active {
                            background-color: var(--color-action-primary-pressed);
                            border-color: var(--color-action-primary-pressed);
                        }
                    }
                    
                    &:disabled {
                        color: var(--color-text-disabled);
                        background-color: var(--color-action-primary-disabled);
                    }
                }
            }
        }
    }
}

.popupFooterHidden {
    composes: popup;

    :global {
        .ant-picker-panel {
            .ant-picker-footer {
                display: none;
            }
        }
    }
}