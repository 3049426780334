$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.footer {
    bottom: 0;
    background-color: var(--color-surface-default);
    composes: global(py-4);
    composes: global(px-6);
    width: 100%;

    @include mq($until: md) {
        @include mx(-7);
        width: calc(100% + 2 * var(--spacing-7));
    }

    @include mq(xl) {
        width: 100%;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    @media screen and (min-height: 769px) {
        position: sticky;
    }
}
