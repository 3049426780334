$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.quick-sell-page {
    margin-right: 24px;
    max-width: 1000px;
    min-width: 320px;

    &__create-demo.ant-btn {
        position: absolute;
        right: 20px;
        top: 16px;
        border-style: dashed;
        border-width: 2px;
    }

    &__food-and-drinks {
        display: flex;
        margin: 16px 0;
    }

    &__services {
        &__title {
            margin-top: 16px;
            margin-bottom: 2px;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
        }

        &__select {
            .ant-select-selection--single .ant-select-selection__rendered {
                margin-left: 16px;
                margin-right: 40px;
            }

            .ant-select-arrow {
                right: 16px;
            }
        }
    }

    &__performance {
        display: flex;
        justify-content: space-between;
        max-width: 1000px;
        width: 100%;
        margin: 16px 0;

        &__box {
            border: 1px solid #e6e6e6;
            border-radius: 16px;
            width: 100%;
            min-width: fit-content;
            padding: 16px 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:not(:first-child) {
                margin-left: 20px;
            }
        }

        &__title {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #808080;
            margin-bottom: 8px;
        }
        &__value {
            font-weight: 500;
            font-size: 36px;
            line-height: 36px;
            color: #262626;
        }
    }

    &__select-service {
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        margin-top: 40px;
    }

    &__products {
        &__header-tooltip {
            border-bottom: 1px dashed #bfbfbf;
            padding-bottom: 6px;

            &__overlay.ant-tooltip {
                filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
                max-width: 380px;
                .ant-tooltip-inner {
                    background: #262626;
                    border-radius: 4px;
                }
            }

            &__title {
                font-weight: bold;
                color: #ffffff;
                font-size: 14px;
                line-height: 22px;
            }
            &__description {
                color: #cdcdcd;
                font-size: 13px;
                line-height: 20px;
            }
        }
        &__internal-name {
            color: #bfbfbf;
        }
        .archived {
            color: #bfbfbf;
        }

        .ant-col.col-content & {
            &.ant-table-wrapper {
                margin-bottom: 74px;
                padding-bottom: 16px;

                .ant-table {
                    min-width: 650px;
                }
            }
        }
    }

    .ant-col.col-content.ant-col-md-24.ant-col-lg-22 {
        max-width: 1000px;
        width: 100%;
    }

    &__missing-data {
        display: grid;
        grid-template-areas:
            "image title"
            "image description";
        background: #fafafa;
        border-radius: 8px;
        padding: 16px;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        &__image {
            grid-area: image;
            margin-right: 16px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
        &__title {
            grid-area: title;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #262626;
        }
        &__description {
            grid-area: description;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #262626;
        }
    }

    &__off {
        background-color: #fafafa;
        padding: 24px;
        border-radius: 2px;
        font-size: 16px;
        line-height: 24px;
        color: #595959;
        margin-top: 16px;

        &__header {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #262626;
        }

        &__title {
            font-weight: 600;
            margin-top: 16px;
        }

        &__subtitle {
            font-size: 14px;
            line-height: 22px;
            color: #8c8c8c;
        }
    }
}

@media screen and (max-width: 900px) {
    .quick-sell-page {
        &__performance {
            flex-direction: column;
            width: initial;
            align-items: center;

            &__box {
                padding: 8px 6px;

                &:not(:first-child) {
                    margin-top: 16px;
                    margin-left: 0;
                }
            }

            &__title {
                font-size: 14px;
                margin-bottom: 2px;
            }
            &__value {
                font-size: 24px;
            }
        }
    }
}
