$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(px-6);
    composes: global(py-4);
    position: relative;
}

.containerItem {
    composes: container;
    & + & {
        border-top: 1px solid var(--color-border-primary-subtle);
    }

    &:last-of-type {
        border-bottom: 1px solid var(--color-border-primary-subtle);
    }
}

.containerPrimary {
    composes: containerItem;
    background-color: var(--color-surface-default);
}

.containerHeader {
    composes: container;
    background-color: var(--color-surface-default);
}

.containerHeaderTop {
    composes: containerHeader;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
}

.containerHeaderBottom {
    composes: containerHeader;
    border-bottom: 1px solid var(--color-border-primary-subtle);
}

.innerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
}

.innerContainerHeaderTop {
    composes: innerContainer;
    composes: global(cx-4);
    justify-content: flex-end;
}

.cellGrow {
    flex: 1 1 auto;
}

.cellStatic {
    flex: none;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    color: var(--color-text-default);
    overflow: hidden;
    composes: global(cx-6);
}

.detailsContainer {
    display: flex;
    flex: 1 1 auto;

    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    @include mq($until: md) {
        @include cy(1);
    }

    @include mq(md) {
        flex-direction: row;
        align-items: center;
        @include cx(6);
    }
}

.cell {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cellHeader {
    composes: global(text-subheading);
}

.cellName {
    composes: global(text-subheading);
    composes: cell;
    flex: 1 1 auto;

    @include mq($until: md) {
        min-width: 100%;
    }
}

.cellSmall {
    composes: cell;
    width: 120px;
    flex: none;

    @include mq($until: md) {
        width: auto;
        color: var(--color-text-subtle);
    }
}

.cellMedium {
    composes: cell;
    width: 150px;
    flex: none;

    @include mq($until: md) {
        width: auto;
        color: var(--color-text-subtle);
    }
}

.cellTiny {
    composes: cell;
    width: 36px;
    flex: none;
}

.cellInteractive {
    z-index: 1;
}

.sentenceCase {
    &::first-letter {
        text-transform: uppercase;
    }
}

.badge {
    @include my(-1);
    font-size: 12px;
}

.rowEditLink {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media (hover: hover) {
        &:hover {
            background-color: rgba(var(--color-action-secondary-depressed--rgb), 0.1);
        }

        @include safe-focus-removal {
            &:focus:not(:active):not(.disabled) {
                border: 2px solid var(--color-border-highlight-default);
            }
        }
    }
}
