$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.quantityinput {
    width: 50px;
    height: 50px;
    border-radius: 27px;
    border: solid var(--theme-primary-color) 1px;
    text-align: center;
    font-size: 20px;
    color: var(--theme-primary-color);

    &::-webkit-inner-spin-button {
        appearance: none;
    }

    &::-webkit-outer-spin-button {
        appearance: none;
    }
}

.quantityinput--container {
    display: flex;
    justify-content: space-around;
}

.quantityinput--decrement,
.quantityinput--increment {
    max-width: 50px;
    max-height: 50px;
    padding: 12px 0;
    border-radius: 24px;
    line-height: 28px;
    padding-left: 2px;
    background-color: #f2f2f2;
    color: #000;
    font-size: 22px;
}
