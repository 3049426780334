$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.container {
    margin-left: calc(-1 * var(--spacing-2));
    margin-top: calc(-1 * var(--spacing-2));
}

.value {
    // composes: global(text-button);
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-2);
    display: inline-flex;
    align-items: center;
    background-color: var(--color-surface-background);
    color: var(--color-text-default);
    font-weight: 500;
    border-radius: var(--border-radius);
    margin-left: var(--spacing-2);
    margin-top: var(--spacing-2);
}

.button {
    composes: global(button-reset);

    @include safe-focus-removal {
        &:focus:not(:active):not(.disabled) {
            background-color: rgba(var(--color-action-secondary-depressed--rgb), 0.1),
        }
    }

    @media (hover: hover) {
        &:hover {
            background-color: rgba(var(--color-action-secondary-depressed--rgb), 0.1),
        }
    }

    &:active {
        background-color: rgba(var(--color-action-secondary-depressed--rgb), 0.2),
    }


    &.disabled {
        color: var(--color-text-disabled);
        background-color: var(--color-surface-background)
    }
}