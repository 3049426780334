$env: "production";

@import "../../../../core/styles/settings/media-query";
@import "../../../../core/styles/settings/spacing";
@import "../../../../core/styles/settings/grid";
@import "../../../../core/styles/settings/colors";
@import "../../../../core/styles/settings/container-spacing";
@import "../../../../core/styles/mixins/button";
@import "../../../../core/styles/mixins/card";
@import "../../../../core/styles/mixins/elevation";
@import "../../../../core/styles/mixins/focus";
@import "../../../../core/styles/mixins/form";
@import "../../../../core/styles/mixins/grid";
@import "../../../../core/styles/mixins/link";
@import "../../../../core/styles/mixins/list";
@import "../../../../core/styles/mixins/palette";
@import "../../../../core/styles/mixins/settings-to-props";
@import "../../../../core/styles/mixins/spacing";
@import "../../../../core/styles/mixins/visibility";
@import "../../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../../styles/settings/border-radius";
@import "../../../../styles/settings/card";
@import "../../../../styles/settings/depth";
@import "../../../../styles/settings/elevation";
@import "../../../../styles/settings/layout";
@import "../../../../styles/settings/palette";
@import "../../../../styles/settings/spacing";
@import "../../../../styles/settings/typography";

.ant-col.col-content div.ant-table-wrapper {
    max-width: 1000px;
    overflow-x: auto;

    a {
        color: rgba(0, 0, 0, 0.65);
    }

    table {
        border-collapse: collapse;
    }

    table,
    th {
        // TODO Find way to do this without !important.
        // Needed right now because Ant4 turns on borders with very high specificity.
        border: none !important;
    }

    .ant-table.ant-table-bordered > .ant-table-container {
        border-left: none;
    }

    th,
    thead tr {
        background-color: transparent;
    }

    tr {
        border-right: 1px solid #e8e8e8;
    }

    .ant-table-thead tr {
        border-right: 1px solid transparent;
    }

    td {
        border: 1px solid #e8e8e8;
    }

    &.actionable-row-table {
        td:hover {
            cursor: pointer;
        }
    }

    .ant-pagination.ant-table-pagination {
        display: flex;
        float: none;
        position: fixed;
        bottom: 0;
        left: 0px;
        background: #fff;
        width: 100%;
        padding: 10px 24px;
        padding-right: calc(100vw - 1224px);
        margin-bottom: 0;
        z-index: 5;
        border-top: 1px solid #e3e5e8;

        @include mq(lg) {
            left: $menu-width;
            width: calc(100vw - #{$menu-width});
        }
    }

    .ant-pagination.ant-table-pagination {
        display: flex;
        float: none;

        .ant-pagination-item:not(.ant-pagination-item-active),
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            border-color: transparent;
        }

        .ant-pagination-total-text {
            color: rgba(0, 0, 0, 0.25);
            margin-right: auto;
            white-space: nowrap;
            padding-right: 5px;
        }
    }

    tr.ant-table-row.ant-table-row-level-1 td {
        background-color: rgb(250, 250, 250);
    }

    tr.ant-table-row.ant-table-row-level-1 td:last-child {
        background-color: none;
        background: none;
    }

    .ant-table-thead th {
        font-weight: bold;
    }

    .col-right {
        text-align: right;
    }

    .col-center {
        text-align: center;
    }

    .col-actions {
        width: 44px;
        display: table-cell;

        button {
            color: rgba(0, 0, 0, 0.65);

            &:hover,
            &:active {
                border: none;
            }
        }
    }

    .child-icon {
        margin-left: 20px;
    }
}
