// private
@function mergeConfigWithDefault($gridConfig: ()) {
    @return map-merge($defaultGridConfig, $gridConfig);
}

// create a container with max width and padding
// optionally pass in grid config to modify defaults
@mixin gridContainer($gridConfig: ()) {
    $config: mergeConfigWithDefault($gridConfig);
    $maxWidth: map-get($config, "maxWidth");
    $padding: map-get($config, "gridPadding");

    width: 100%;
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;

    @each $bp, $val in $padding {
        @include mq($bp) {
            padding-left: map-get($padding, $bp);
            padding-right: map-get($padding, $bp);
        }
    }
}

// create grid
// optionally pass in grid config to modify defaults
@mixin grid($gridConfig: ()) {
    $config: mergeConfigWithDefault($gridConfig);
    $gridGutters: map-get($config, "gridGutters");

    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @each $bp, $val in $gridGutters {
        @include mq($bp) {
            @if (map-has-key($val, x)) {
                column-gap: map-get($val, x);
            }
            @if (map-has-key($val, y)) {
                row-gap: map-get($val, y);
            }
        }
    }
}

// create a column
// param cols number of columns to span
// optionally pass in grid config to modify defaults
@mixin gridColumn($cols: 1, $gridConfig: ()) {
    $config: mergeConfigWithDefault($gridConfig);
    $gridGutters: map-get($config, "gridGutters");

    @each $gutterBp, $val in $gridGutters {
        @include mq($gutterBp) {
            grid-column-start: auto;
            grid-column-end: span #{$cols};
        }
    }
}

// specify column start
// @param start (int) - column to start with
// optionally pass in grid config to modify defaults
@mixin gridColumnStart($start, $gridConfig: ()) {
    $config: mergeConfigWithDefault($gridConfig);
    $gridGutters: map-get($config, "gridGutters");

    @each $gutterBp, $val in $gridGutters {
        @include mq($gutterBp) {
            grid-column-start: $start;
        }
    }
}

// utility to generate grid classes @see https://github.com/d3zza/simple-scss-grid#gridclassbaseclass
// optionally pass in grid config to modify defaults
@mixin gridClasses($container-selector: "grid-container", $row-selector: "grid", $col-prefix: "col-", $gridConfig: ()) {
    $config: mergeConfigWithDefault($gridConfig);
    $colCount: map-get($config, "colCount");

    // container
    %#{$container-selector},
    .#{$container-selector} {
        @include gridContainer($config);
    }

    // row
    %#{$row-selector},
    .#{$row-selector} {
        @include grid($config);
    }

    // columns
    @each $bp, $val in $grid-breakpoints {
        @include mq($bp) {
            @for $i from 1 to ($colCount + 1) {
                $col-selector: "#{$col-prefix}#{$bp}-#{$i}";

                %#{$col-selector},
                .#{$col-selector} {
                    @include gridColumn($i, $config);
                }
            }
        }
    }
    // column start
    @each $bp, $val in $grid-breakpoints {
        @include mq($bp) {
            @for $i from 0 to ($colCount + 1) {
                $start-selector: "#{$col-prefix}#{$bp}-start-#{$i}";

                %#{$start-selector},
                .#{$start-selector} {
                    @include gridColumnStart($i, $config);
                }
            }
        }
    }
}
