$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(p-5);
    border: 1px dashed var(--color-border-primary-subtle);
    border-radius: 2px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @at-root :global(.field-errors--error) & {
        @include mb(5);
    }
}

.containerDragActive {
    composes: container;
    border-color: var(--color-border-highlight-default);
}

.dragMessage {
    composes: global(text-reset);
    composes: global(mt-3);
    color: var(--color-text-subtle);

    .containerDragActive & {
        color: var(--color-text-default);
    }
}

.mediaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.media {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border-primary-subtle);
    height: auto;
    max-height: 190px;
    max-width: 100%;

    @include mq(md) {
        max-height: 350px;
    }
}

.footer {
    composes: global(cx-5);
    padding-top: calc(var(--spacing-5) - var(--spacing-1));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fileName {
    color: var(--color-text-subtle);
    flex: 1 1 auto;
}

.icon {
    composes: global(mb-5);
    color: var(--color-icon-primary-subtle);
}
