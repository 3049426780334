$env: "production";

@import "../../../core/styles/settings/media-query";
@import "../../../core/styles/settings/spacing";
@import "../../../core/styles/settings/grid";
@import "../../../core/styles/settings/colors";
@import "../../../core/styles/settings/container-spacing";
@import "../../../core/styles/mixins/button";
@import "../../../core/styles/mixins/card";
@import "../../../core/styles/mixins/elevation";
@import "../../../core/styles/mixins/focus";
@import "../../../core/styles/mixins/form";
@import "../../../core/styles/mixins/grid";
@import "../../../core/styles/mixins/link";
@import "../../../core/styles/mixins/list";
@import "../../../core/styles/mixins/palette";
@import "../../../core/styles/mixins/settings-to-props";
@import "../../../core/styles/mixins/spacing";
@import "../../../core/styles/mixins/visibility";
@import "../../../core/styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.root {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    min-width: 300px;
}

.header {
    flex: none;
    height: 64px;
    background-color: var(--color-surface-default);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    z-index: $z-header;
}

.outerContainer {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    position: relative;
}

.contentContainer {
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    // Apply ant tree style overrides to redesigned layouts only,
    // TODO when other product tree pages have been redesigned, move these styles to global ant overrides
    :global {
        .tree-wrapper {
            margin-top: 0;

            @at-root :global(.field-errors--error) & {
                border-color: var(--color-border-critical-default);
                @include mb(3);
            }
        }

        .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
            background-color: var(--color-action-primary-default);
        }

        .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
            background-color: var(--color-action-primary-default);
            border-color: var(--color-action-primary-default);
        }

        .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
            background-color: var(--color-surface-disabled);
            border-color: var(--color-border-primary-disabled);
        }

        .ant-tree-checkbox-disabled.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
            background-color: var(--color-border-primary-disabled);
        }
    }
}

.contentContainerLegacy {
    flex-grow: 1;
    overflow: hidden;
    background-color: #f0f2f5;
    position: relative;

    :global(.edit-form--commands) {
        width: 100%;
        margin-left: -24px;
        padding: 10px 16px;
        right: 0;
        overflow-x: auto;

        .close-button {
            margin-right: auto;
            color: #7b8daf;
        }

        @include mq(lg) {
            width: calc(100% - #{$menu-width});
        }
    }
}

// TODO temporary for backwards compatibility. Should not need to rely on global classes here
:global(.edit-form--commands) {
    position: fixed;
    bottom: 0;
    display: flex;
    padding: 10px;
    background: #fff;
    flex: 1;
    width: 100%;
    border-top: solid 1px #e8e8e8;

    // Required to prevent focused inputs from appearing on top of it
    z-index: 5;

    > .ant-btn {
        margin-right: 24px;
    }
}

// TODO temporary for backwards compatibility during CMS redesign
:global {
    .content--container {
        height: 100%;

        &__row {
            height: 100%;
        }

        .content__col {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 100%;

            &.content__col--services {
                height: calc(100vh - 60px);

                .content__col-list {
                    padding: 0;
                    height: 100%;
                }
            }

            &.content__col--white {
                background-color: #fff;
                padding-right: 0;

                &.no-top-header {
                    max-height: 100vh;
                    padding-top: 60px;
                }
            }
        }

        .content__col-list {
            padding: 24px;
            background-color: #f2f2f8;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .content__col-list-white {
            padding: 24px;
            padding-right: 0;
            background-color: #fff;
            max-height: 100%;
        }

        .content__col-edit {
            background-color: #fff;
            height: calc(100% - 76px);
            overflow-y: auto;
            overflow-x: hidden;

            > * {
                padding: 24px;
            }

            .ant-form > .ant-btn {
                margin-right: 24px;
            }

            .link-back {
                &__container {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 0;

                    @media screen and (min-width: 1200px) {
                        display: none;
                    }
                }

                &__link {
                    padding: 24px 24px 0px;
                    color: currentColor;
                }
            }
        }

        .content__col--pricelistsedit {
            background-color: #fff;
        }

        // only used by pricelist
        .content__col-edit-slim {
            background-color: #fff;
            height: calc(100% - 76px);
            overflow-y: auto;
            overflow-x: hidden;

            padding: 24px;

            .ant-form > .ant-btn {
                margin-right: 24px;
            }

            .link-back i {
                margin-top: 15px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }

    .content--container__row .content__col {
        display: none;
    }

    .content--container__row .content__col:last-child {
        display: block;
    }

    .content--container__row .content__col--catalogue {
        display: block;
    }

    @media screen and (min-width: 1200px) {
        .content--container__row .content__col {
            display: block;
        }
    }

    .edit-form--commands {
        position: fixed;
        bottom: 0;
        display: flex;
        padding: 10px;
        background: #fff;
        flex: 1;
        width: 100%;
        border-top: solid 1px #e8e8e8;

        // Required to prevent focused inputs from appearing on top of it
        z-index: 5;

        > .ant-btn {
            margin-right: 24px;
        }
    }

    .edit-drawer:not(.edit-drawer--catalogue) {
        &.ant-drawer-right {
            .ant-drawer-wrapper-body {
                right: 0;
                position: fixed;
                width: 100%;

                @include mq(896px) {
                    max-width: 680px;
                }
            }
        }

        .ant-drawer-content {
            overflow: hidden;
        }

        .edit-form--commands {
            max-width: 680px;
            margin-left: -24px;
            padding: 10px 16px;
            right: 0;
            overflow-x: auto;

            .close-button {
                margin-right: auto;
                color: #7b8daf;
            }
        }
    }

    .edit-drawer--catalogue {
        .ant-drawer-body {
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100% - 131px);
            flex: none;
            position: static;
        }
    }

    .title-case {
        text-transform: capitalize;
    }
}
