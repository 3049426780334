.zoomUpIn {
    animation-duration: 0.2s;
    animation-name: zoomUpIn;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.zoomUpOut {
    animation-duration: 0.2s;
    animation-name: zoomUpOut;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes zoomUpIn {
    0% {
        transform: scale(0.75);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes zoomUpOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.75);
        opacity: 0;
    }
}
