.list {
    composes: global(list-reset);
    composes: global(py-2);
    width: 100%;
}

.listItem {
    composes: global(px-4);
    height: 30px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.listItemSelected {
    composes: listItem;
    background-color: rgba(var(--color-action-secondary-hover--rgb), 0.5);
    font-weight: 600;
}
