$env: "production";

@import "../../styles/settings/media-query";
@import "../../styles/settings/spacing";
@import "../../styles/settings/grid";
@import "../../styles/settings/colors";
@import "../../styles/settings/container-spacing";
@import "../../styles/mixins/button";
@import "../../styles/mixins/card";
@import "../../styles/mixins/elevation";
@import "../../styles/mixins/focus";
@import "../../styles/mixins/form";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/link";
@import "../../styles/mixins/list";
@import "../../styles/mixins/palette";
@import "../../styles/mixins/settings-to-props";
@import "../../styles/mixins/spacing";
@import "../../styles/mixins/visibility";
@import "../../styles/mixins/typography";
@import "~sass-mq/mq";

@import "../../../styles/settings/border-radius";
@import "../../../styles/settings/card";
@import "../../../styles/settings/depth";
@import "../../../styles/settings/elevation";
@import "../../../styles/settings/layout";
@import "../../../styles/settings/palette";
@import "../../../styles/settings/spacing";
@import "../../../styles/settings/typography";

.container {
    composes: global(px-6);
    composes: global(py-4);
    background-color: var(--color-surface-default);
    & + & {
        border-top: 1px solid var(--color-border-primary-subtle);
    }
}

.innerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
}

.check {
    flex: none;
    display: flex;
    min-height: 40px;
    align-items: center;
    justify-content: center;

    @include mq($until: md) {
        align-self: flex-start;
    }
}

.thumb {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--color-surface-background);
    color: var(--color-icon-primary-subtle);
    border-radius: var(--border-radius);
    overflow: hidden;

    @include mq($until: md) {
        display: none;
    }
}

.check + .thumb {
    @include ml(6);
}

.contentContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    color: var(--color-text-default);
}

.thumb + .contentContainer {
    @include ml(4);
}

.check + .contentContainer {
    @include ml(5);
}

.actionsContainer {
    composes: global(ml-4);
    flex: 0 1 none;
    align-self: flex-start;
}

.heading {
    composes: global(text-reset);
    composes: global(text-subheading);
}

.body {
    margin: 0;
    padding: 0;
    composes: global(text-body);
}
